// src/homePage.js
import React, {useEffect, useState} from 'react';
import 'tui-grid/dist/tui-grid.css';
import './home.css'
import api from '../api';

function HomePage() {
    const [dataValue, setdateValue] = useState({})

    useEffect(() => {
        api.get('/api/dmp/count').then((res)=> setdateValue(res.data))
            .catch((error) => console.error("home api를 확인해보세요.", error));
    }, []);



    console.log("dataValue 값 확인", dataValue);


    return (
        <div className="App">
            <section className="main-img">
                <div className="img-title">
                    {/*<img src={`${process.env.PUBLIC_URL}/close-up-blurry-researcher-holding-glassware.png`} alt="KFRI Logo" />*/}
                </div>
                <div className="main-title">
                    <h1>Welcome to</h1>
                    <h2>KFRI Database Repository</h2>
                </div>
                <div className="main-input-box">
                    <input type="text" placeholder="찾으시는 데이터를 입력해주세요."/>
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                </div>
                <div className="mainDataNumber">
                    <div className="registerDMP">
                        <h2>등록된 DMP </h2>
                        <span>{dataValue.count?.CntDmp}</span>
                    </div>
                    <div className="registerData">
                        <h2>등록된 데이터 </h2>
                        <span>{dataValue.count?.CntData}</span>
                    </div>
                    <div className="openDMP">
                        <h2>공개된 DMP </h2>
                        <span>{dataValue.count?.CntOpenData}</span>
                    </div>
                </div>
            </section>
            <section>
                <div className="majorServiceIntro">
                    <h1>
                        주요 서비스
                    </h1>
                    <span>KFRI 관련 데이터를 등록 할 수 있습니다.</span>
                    <div className="majorService-img">
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/btn_add.png`} alt="search Logo" />
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/btn_add.png`} alt="search Logo" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="noticeIntro">
                    <div>
                        <div  className="notice-contents">
                            <h1>공지사항</h1>
                            <img src={`${process.env.PUBLIC_URL}/icon_notice.png`} alt="search Logo" />
                        </div>
                    </div>
                    <div className="div_notice">
                        <div>식품데이터플랫폼 사이트가 오픈되었습니다.</div>
                        <div >2022.01.01</div>
                    </div>
                        <span>더보기</span>
                        <img className="usermanual-img" src={`${process.env.PUBLIC_URL}/icon_usermanual.png`} alt="search Logo" />
                </div>
            </section>


        </div>
    )
}

export default HomePage;
