import React from "react";
import './footer.css';

function footer() {

    return(
        <>
            <div className="footer-comtainer">
                <div className="footer-img">
                    <img src={`${process.env.PUBLIC_URL}/CI_FOOTER_KFRI.png`} alt="KFRI footer" />
                </div>
                <div className="footer-letter">
                    <p>개인정보처리방침(55365) 전라북도 완주군 이서면 농생명로 245 지능화정책팀</p>
                    <p>Copyrights © 2022 www.kfri.re.kr All Rights Reserved.</p>
                </div>
            </div>

        </>
    )
}
export default  footer;