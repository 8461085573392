import React, { useRef, useState, useEffect } from 'react';
import Grid from '@toast-ui/react-grid';
import 'tui-grid/dist/tui-grid.css';
import '../toast/toastGrid.css';
import { useNewDmpToastData } from "../stores/newWriteDmp";

const DmpToastGrid = ({ MethodData, DateSets }) => {

    console.log("데이터 불러오기 확인", DateSets, MethodData);

    const gridRef = useRef(null);
    const [sampleData, setSampleData] = useState([]); // 시료데이터 저장 배열
    const [methodData, setMethodData] = useState([]); // 방법데이터 저장 배열
    const [resultData, setResultData] = useState([]); // 결과데이터 저장 배열

    const { updateNewDmpToastDataSets, addnewDmpToastDataSets } = useNewDmpToastData();

    // Custom editor for handling shift + Enter
    class CustomTextEditor {
        constructor(props) {
            const el = document.createElement('textarea');
            el.style.width = '100%';
            el.style.height = '100%';
            el.value = String(props.value);
            this.el = el;
        }

        getElement() {
            return this.el;
        }

        getValue() {
            return this.el.value;
        }

        mounted() {
            this.el.focus();
            this.el.addEventListener('keydown', (e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                    e.stopPropagation();  // Prevent grid from interpreting Enter as row submission
                }
            });
        }
    }

    // Columns definition with custom editor for description
    const columns = MethodData === "방법데이터" ? [
        { header: 'METHOD INDEX', name: 'index', editor: 'text', width: 200 },
        { header: 'METHOD NAME', name: 'name', editor: 'text', width: 200 },
        {
            header: 'DATATYPE_DESCRIPTION',
            name: 'methoddescription',
            editor: CustomTextEditor,  // Use custom editor
            width: 400,
            lineHeight: '1.5',
            whiteSpace: 'pre-wrap'
        },
        {
            name: 'delete',
            header: 'Delete',
            formatter: ({ row }) => `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`
        },
    ] : [
        { header: 'FIELD', name: 'field', editor: 'text', width: 200 },
        { header: 'TYPE', name: 'type', editor: 'text', width: 200 },
        { header: 'UNIT', name: 'unit', editor: 'text', width: 200 },
        {
            header: 'DATATYPE_DESCRIPTION',
            name: 'description',
            editor: CustomTextEditor,  // Use custom editor
            lineHeight: '1.5',
            whiteSpace: 'pre-wrap'
        },
        {
            name: 'delete',
            header: 'Delete',
            formatter: ({ row }) => `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`
        },
    ];

    // 데이터가 있을 시 처음 보여줄때
    useEffect(() => {
        if (DateSets?.DataSet?.length > 0) {
            const sampleList = [];
            const methodList = [];
            const resultList = [];

            DateSets.DataSet.forEach(item => {
                if (MethodData === "시료데이터") {
                    sampleList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        field: item.DtaFld,
                        type: item.DtaTyp,
                        unit: item.DtaUnit,
                        description: item.DtaDsc,
                        delete: '삭제'
                    });
                } else if (MethodData === "방법데이터") {
                    methodList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        index: item.DtaMtdIdx,
                        name: item.DtaMtdNm,
                        methoddescription: item.DtaDsc,
                        delete: '삭제'
                    });
                } else if (MethodData === "결과데이터") {
                    resultList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        field: item.DtaFld,
                        type: item.DtaTyp,
                        unit: item.DtaUnit,
                        description: item.DtaDsc,
                        delete: '삭제'
                    });
                }
            });

            setSampleData(sampleList);
            setMethodData(methodList);
            setResultData(resultList);
        }
    }, [DateSets]);

    const handleAddRow = () => {
        const newId = DateSets.id;
        const newRow = MethodData === "방법데이터" ? {
            id: newId,
            index: '-',
            name: '-',
            methoddescription: "-",
            delete: '삭제'
        } : {
            id: newId,
            field: '-',
            type: '-',
            unit: '-',
            description: "-",
            delete: '삭제'
        };

        if (MethodData === "방법데이터") {
            setMethodData(prevState => {
                const updatedData = [...prevState, newRow];
                DateSets.DataSet = [...DateSets.DataSet, newRow]; // `DataSet`에 직접 추가
                return updatedData;
            });
        } else if (MethodData === "시료데이터") {
            setSampleData(prevState => {
                const updatedData = [...prevState, newRow];
                DateSets.DataSet = [...DateSets.DataSet, newRow]; // `DataSet`에 직접 추가
                return updatedData;
            });
        } else if (MethodData === "결과데이터") {
            setResultData(prevState => {
                const updatedData = [...prevState, newRow];
                DateSets.DataSet = [...DateSets.DataSet, newRow]; // `DataSet`에 직접 추가
                return updatedData;
            });
        }

        addnewDmpToastDataSets(newRow);
    };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }

        if (MethodData === "방법데이터") {
            setMethodData(prevData => {
                const updatedData = prevData.filter(row => row.rowKey !== parsedRowKey);
                DateSets.DataSet = updatedData; // `DataSet`에서 삭제된 데이터 반영
                return updatedData;
            });
        } else if (MethodData === "시료데이터") {
            setSampleData(prevData => {
                const updatedData = prevData.filter(row => row.rowKey !== parsedRowKey);
                DateSets.DataSet = updatedData; // `DataSet`에서 삭제된 데이터 반영
                return updatedData;
            });
        } else if (MethodData === "결과데이터") {
            setResultData(prevData => {
                const updatedData = prevData.filter(row => row.rowKey !== parsedRowKey);
                DateSets.DataSet = updatedData; // `DataSet`에서 삭제된 데이터 반영
                return updatedData;
            });
        }
    };

    const handleGridUpdated = ({ changes }) => {
        changes.forEach(({ rowKey, columnName, value }) => {
            let updatedList = [];

            if (MethodData === "방법데이터") {
                updatedList = methodData.map((item, index) =>
                    index === rowKey ? { ...item, [columnName]: value } : item
                );
                setMethodData(updatedList);
                DateSets.DataSet = updatedList; // `DataSet` 업데이트 반영

            } else if (MethodData === "시료데이터") {
                updatedList = sampleData.map((item, index) =>
                    index === rowKey ? { ...item, [columnName]: value } : item
                );
                setSampleData(updatedList);
                DateSets.DataSet = updatedList; // `DataSet` 업데이트 반영
            } else if (MethodData === "결과데이터") {
                updatedList = resultData.map((item, index) =>
                    index === rowKey ? { ...item, [columnName]: value } : item
                );
                setResultData(updatedList);
                DateSets.DataSet = updatedList; // `DataSet` 업데이트 반영
            }

            updateNewDmpToastDataSets(updatedList[rowKey].id, { [columnName]: value });
        });
    };

    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

    }, []);

    return (
        <div className="toast-container">
            <div className="row-btn">
                <button className="public-data-addrow" onClick={handleAddRow}>
                    행추가
                </button>
            </div>
            <Grid
                ref={gridRef}
                data={MethodData === "방법데이터" ? methodData : MethodData === "시료데이터" ? sampleData : resultData}
                columns={columns}
                rowHeight={'auto'}
                bodyHeight={300}
                columnOptions={{ resizable: true }}
                rowHeaders={['rowNum']}
                onAfterChange={handleGridUpdated}
            />
        </div>
    );
};

export default DmpToastGrid;
