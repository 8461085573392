import {create} from "zustand";
import {persist} from "zustand/middleware";

 const useCallImgStore = create(persist(
    (set) => ({
        taskDataSets: [],
        setTaskDataSets: (data) => set({ taskDataSets: data }),
    }),
    {
        name: 'taskDataStorage', // LocalStorage에 저장될 이름
        getStorage: () => localStorage, // LocalStorage 사용
    }
));


const useAllDmpData = create(set =>({
    allDmpDataSets: [],
    set: (newImgDataSets) => set({
        allDmpDataSets : newImgDataSets
    })
}))



export {useCallImgStore, useAllDmpData};