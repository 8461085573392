import React, {useState} from "react";
import {Link} from "react-router-dom";
import './subminDataStatus.css';
import {Calendar} from "react-big-calendar";
function SubminDataStatus() {

    const [selectedYear, setSelectedYear] = useState('all');

    const adminMenu = [
        {
            'name': "홈으로",
            'path': "/"
        },
        {
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage/adminDataPage"
        },
        {
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "데이터 제출 목록 일괄(고민중)",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmissionStatus/dataSubmissionStatus"
        },
        {
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "DMP 계획 및 제출 현황",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "DMP 접근권한 확인",
            'path': "/adminPage/dmpAccessCheck/dmpAccessCheck"
        },
    ]
    const tableData = [
        { DmpNo : 100, id: 1, year: '2023', projectName: 'Project A', lead: 'Test계정1', dmpStatus: '허용', lastModified: '3' },
        { DmpNo : 101, id: 2, year: '2023', projectName: 'Project B', lead: 'Test계정2', dmpStatus: '허용', lastModified: '4' },
        { DmpNo : 102, id: 3, year: '2023', projectName: 'Project B', lead: 'Test계정3', dmpStatus: '불가', lastModified: '3' },
        { DmpNo : 103, id: 4, year: '2021', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '1' },
        { DmpNo : 104, id: 5, year: '2021', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '2' },
        { DmpNo : 105, id: 6, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3' },
        { DmpNo : 106, id: 7, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3' },
        { DmpNo : 107, id: 8, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3' },
        { DmpNo : 108, id: 9, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3' },
        { DmpNo : 109, id: 10, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '4' },
    ];
    const filteredData = selectedYear === 'all'
        ? tableData
        : tableData.filter(row => row.year === selectedYear);

    return(
        <>
            <div className="adminpage-container">
                <h1>관리자페이지(데이터 제출현황)</h1>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <div key={index} className="menu-item">
                                <Link to={item.path}>
                                    {item.name}
                                    <span className="arrow">▼</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>
                                과제수행년도
                            </h3>
                            <select value={selectedYear} className="submin-year" onChange={(e) => setSelectedYear(e.target.value)}>
                                <option value="all">전체</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>DmpNo</th>
                                <th>과제번호</th>
                                <th>연차</th>
                                <th>DMP 과제명</th>
                                <th>연구책임자명</th>
                                <th>데이터담당자명</th>
                                <th>제출데이터세트 수</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData.map((row) => (
                                <tr key={row.id} className="dmplist-Data">
                                    <td>{row.DmpNo}</td>
                                    <td>{row.id}</td>
                                    <td>{row.year}</td>
                                    <td>{row.projectName}</td>
                                    <td>{row.lead}</td>
                                    <td>{row.dmpStatus}</td>
                                    <td>{row.lastModified}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}

export  default SubminDataStatus;