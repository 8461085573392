import React, { useEffect, useRef, useState } from "react";
import Grid from "@toast-ui/react-grid";
import '../toast/toastGrid.css';
import { useProductionDataSetsStore} from "../stores/lastDmpData";
import { useNewDmpProductionPlan } from "../stores/newWriteDmp";

function DmpDataProductionPlan({updateProductionPn}) {
    const gridRef = useRef(null);
    const [list, setList] = useState([]);

    //new date 생성 시
    const { updateProductionPlanDataSet, addNewProductionPlanDataSet } = useNewDmpProductionPlan();


    console.log("updateProductionPn@@", updateProductionPn);

    //값을 modify로 불러온 후 다시 수정할때 쓰이는 변수
    const {setProductionDataSets} = useProductionDataSetsStore();

    useEffect(() => {
        if (updateProductionPn && updateProductionPn.Prod) {
            const formattedData = updateProductionPn.Prod.map(item => ({
                DmpPrdNo:item.DmpPrdNo,
                DmpNo:item.DmpNo,
                dataType: item.DtaNm,
                count: item.ProCnt,
                proPlan: item.ProPlan,
                InsDt: item.InsDt,
                UpdDt: item.UpdDt,
                delete: '삭제',
            }));
            setList(formattedData);
        } else {
            setList([]); // 또는 초기값을 설정
        }


    }, [updateProductionPn]);


    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor: {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DATABASE_MI', value: 'DATABASE_MI' },
                        { text: 'PROJECT', value: 'PROJECT' },
                        { text: 'PROJECT_FILE', value: 'PROJECT_FILE' },
                        { text: 'NORMALIZATION', value: 'NORMALIZATION' }
                    ]
                }
            },
            width: 300,
        },
        {
            header: '개수',
            name: 'count',
            editor: 'text',
            width: 300
        },
        {
            header: '생산계획',
            name: 'proPlan',
            editor: 'text',
            width: 300
        },
        {
            header: 'Delete',
            name: 'delete',
            formatter: ({ row }) => `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        },
    ];
    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            dataType: '-',
            count: '-',
            proPlan: '-',
        };
        setList(prevList => [...prevList, newRow]);
        addNewProductionPlanDataSet(newRow); // Add new row to the global state
    };


    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }
        setList(prevList => prevList.filter(row => row.rowKey !== parsedRowKey));
    };

    const handleGridUpdated = ({ changes }) => {
        changes.forEach(({ rowKey, columnName, value }) => {
            const newList = list.map((item, index) =>
                index === rowKey ? { ...item, [columnName]: value } : item
            );
            setList(newList);

            // Update the global state
            if (['dataType', 'count', 'proPlan'].includes(columnName)) {
                updateProductionPlanDataSet(newList[rowKey].id, { [columnName]: value });
            }
        });
    };


    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });
        setProductionDataSets(list);

    }, [list]);

    return (
        <div className="toast-container">
            <div className="row-btn">
                <button className="public-data-addrow" onClick={handleAddRow}>
                    행추가
                </button>
            </div>
            <Grid
                data={list}
                ref={gridRef}
                columns={columns}
                rowHeight="auto"
                bodyHeight={300}
                columnOptions={{ resizable: true }}
                rowHeaders={['rowNum']}
                onAfterChange={handleGridUpdated}
            />
        </div>
    );
}

export default DmpDataProductionPlan;
