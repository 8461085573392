import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import api from '../api';

function OpenData() {

    const [dataList, setDataList] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        api.get(`/api/open/list?kw&ps&pl`, {
            withCredentials: true
        }).then(res =>
            setDataList(res.data.item)
        );
    }, []);

    console.log(dataList);

    const openDataRowClick = async (DtaSbmSeqNo) => {
        navigate('/openData/detail/page');

    }


    return(
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span>공개 데이터</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 > 데이터 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                </div>
                <div>
                    <div className="btn-container">
                        <div className="history-select">
                            <div>총 2건</div>

                        </div>
                        <div className="btn-check">
                            {/*<button className="create02-dmp">*/}
                            {/*    <Link to="/dmpobj/dmpObjCreate">*/}
                            {/*        데이터 신청서 작성*/}
                            {/*    </Link>*/}
                            {/*</button>*/}
                          
                        </div>
                    </div>
                    <div className="dmplist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th className="obn01">과제명</th>
                            <th className="obn02">데이터셋 / 설명</th>
                            <th className="obn04">연구 책임자</th>
                            <th className="obn05">데이터 상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataList?.map((row, index) => (
                                    <tr key={index} className="dmplist-Data">
                                        <td>{row.ProjNm}</td>
                                        <td
                                            onClick={() => openDataRowClick(row.DtaSbmSeqNo)}
                                        >{row.DtaSetNm} / {row.DtaDs} </td>
                                        <td>{row.DtaNm}</td>
                                        <td>공개</td>
                                    </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export  default  OpenData;