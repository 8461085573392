import React, {useState} from "react";
import './dmpObjCreate.css'
import {Link} from "react-router-dom";
import api from '../api';

function DmpObjCreate() {

    const [objNumber ,setObjNumber] = useState("");
    const [objName, setObjName] = useState("");
    const [objLeader, setObjLeader] = useState("");
    const [rspnEmpNo, setRspnEmpNo] = useState("");
    const [dataEmpNo, setDataEmpNo] = useState("");
    const [dataEmpNm, setDataEmpNm] = useState("");

    const ProjSaveBtn = () => {
        try{
            api.post(`/api/dmp/proj` ,
                {
                ProjNo: objNumber,
                ProjNm: objName,
                RspnEmpNo : rspnEmpNo,
                RspnEmpNm: objLeader,
                DataEmpNo : dataEmpNo,
                DataEmpNm : dataEmpNm,
                ProjAnnl : 1,
                ProjDateSt : "2025-07-03",
                ProjDateEd : "2025-07-05",
                ProjAnnlDateSt : "2025-07-03",
                ProjAnnlDateEd : "2025-12-31"
            }, {withCredentials: true},).then((res) =>
                console.log(res.data)
            )
            alert("과제번호 등록이 완료 되었습니다.");
            setObjNumber("");
            setObjName("");
            setObjLeader("");
        }catch (err) {
            console.error(err);
            alert("과제 데이터 api 통신을 확인해보세요.")
        }

    }

    return(
        <>
            <div className="dmpObjData-banner-container">
                <div className="dmpObjData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpObjData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span>과제 등록</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획> 과제 등록</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>과제 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title">
                            <span className="RealData-info">과제번호</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="과제번호를 입력해주세요."
                                   onChange={(e) => setObjNumber(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>과제명</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="과제명을 입력해주세요."
                                   onChange={(e) => setObjName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>연구 책임자 사번</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="연구책임자 사번을 입력해주세요."
                                   onChange={(e) => setRspnEmpNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>데이터 담당자 사번</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="데이터담당자 사번을 입력해주세요."
                                   onChange={(e) => setDataEmpNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>데이터명</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="데이터명을 입력해주세요."
                                   onChange={(e) => setDataEmpNm(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>연구 책임자</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box"  style={{width: '300px'}} placeholder="연구 책임자를 적어주세요."
                                   onChange={(e) => setObjLeader(e.target.value)}
                            />
                        </div>
                    </div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list">
                        <Link to="/dmpManagement/dmpList/dmplist"> 목록</Link>
                    </button>
                    <button id="save-btn" onClick={() =>ProjSaveBtn()} >저장</button>
                </div>
            </div>
        </>
    )
}


export default DmpObjCreate