import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './header.css';
import useLoginAuthStore from "../stores/loginAuth";
import api from '../api';

function Header() {
    const userid = useLoginAuthStore((state) => state.userId);
    const setUserId = useLoginAuthStore((state) => state.setUserId); // 상태 업데이트를 위한 함수 추가

    const [isLoggedOut, setIsLoggedOut] = useState(false);

    // const deleteCookie = (name) => {
    //     document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    // };

    const logouthandler = () => {
        const confirmLogout = window.confirm("정말 로그아웃 하시겠습니까?");
        if (confirmLogout) {
            api.post(`/api/auth/logout`, {}, {
                withCredentials: true
            }).then((res) => {
                alert("로그아웃이 되었습니다.");
                // deleteCookie("JSESSIONID"); // JSESSIONID 쿠키 삭제
                setIsLoggedOut(true); // 로그아웃 상태 업데이트
                setUserId(null); // 사용자 ID 초기화
            }).catch((err) => console.log(err));
        }
    };

    return (
        <>
            <header>
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/CI_KFRI.png`} alt="KFRI Logo" />
                        </Link>
                    </div>
                    <nav className="navbar">
                        <Link to="/">소개</Link>
                        <Link to="/dmpManagement/dmpList/dmplist">데이터 관리 계획</Link>
                        <Link to="/dmpRealData/dmpRealDataList/dmpRealDataList">데이터</Link>
                        <Link to="/openData/openData">공개데이터</Link>
                        <Link to="/">데이터큐레이션</Link>
                    </nav>
                    <div className="login">
                        {
                            userid && !isLoggedOut ? (
                                <div className="login-success">
                                    <Link to="/adminPage/adminDataPage">관리자 메뉴</Link>
                                    <button onClick={logouthandler}>로그아웃</button>
                                </div>
                            ) : (
                                <button>
                                    <Link to="/login/login">로그인</Link>
                                </button>
                            )
                        }
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
