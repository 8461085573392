import {create} from 'zustand';



const useDataSetsToastStore = create(set => ({
        callToastDataSets:[],
        setCallToastDataSets: (newCallToastDataSets) => set({
            callToastDataSets: newCallToastDataSets
        })
    }
))


const useDataSummaryToastStore = create(set => ({
        callSummaryDataSets:[],
        setCallSummaryDataSets: (newCallSummaryDataSets) => set({
            callSummaryDataSets: newCallSummaryDataSets
        })
    }
))

const useDataSummaryToastFilterStore = create(set => ({
        callSummaryFilterDataSets:[],
        setCallSummaryFilterDataSets: (newCallSummaryFilterDataSets) => set({
            callSummaryFilterDataSets: newCallSummaryFilterDataSets
        })
    }
))


export {useDataSetsToastStore, useDataSummaryToastStore, useDataSummaryToastFilterStore}
