import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import api from '../../api';

function DmpRealDataSend() {

    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        api.get(`/api/data/datalist?kw&ps&pl`, {
            withCredentials: true
        }).then(res =>
            setDataList(res.data.items)
        );
    }, []);

    console.log("오픈 데이터 세트", dataList);
    return(
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span>데이터 제출</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 > 데이터 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                </div>
                <div>
                    <div className="btn-Realcontainer">
                        <div>총 10건</div>
                        <div className="btn-Realcheck">

                        </div>
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmpReallist-title">
                            <th>과제번호</th>
                            <th>데이터셋/ 설명</th>
                            <th>연구 책임자</th>
                            <th>제출 상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataList?.map((row) => (
                            <tr key={row.id} className="dmpReallist-Data">
                                <td>{row.id}</td>
                                <td>{row.year}</td>
                                <td>{row.lead}</td>
                                <td>{row.dmpStatus}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export  default  DmpRealDataSend;