import './applicationPage.css'
import React from "react";
function ApplicationPage({setShowPage}) {

    const cancelHandler = () => {
        setShowPage(false);
    }

    return(
        <div className="applicationContainer">
            <div className="application-title">
                <h1>한국식품연구원 데이터 공개 신청서</h1>
                <div className="form-container">
                    <h4>데이터 작성자 정보</h4>
                    <label>
                        <p className="tit">데이터 작성자 번호:</p>
                        <p className="deses">E2024992</p>
                    </label>
                    <label>
                        <p className="tit"> 데이터 작성자 이름:</p>
                        <p className="deses">TEST</p>
                    </label>
                    <label>
                        <p className="tit">데이터 공개 일시:</p>
                        <p className="deses">2024-09-02 03:27:41</p>
                    </label>
                    <label>
                        <p className="tit">데이터 종료 일시:</p>
                        <p className="deses">2024-09-06 03:27:41</p>
                    </label>

                    <label>
                        <p className="tit">신청상태:</p>
                        <p className="deses">신청</p>
                    </label>
                    <label>
                        <p className="tit"> 신청일자:</p>
                        <p className="deses">"2024-09-03 15:20:00"</p>
                    </label>
                    <label>
                        <p className="tit">수정일자:</p>
                        <p className="deses">"2024-09-03 15:25:20"</p>
                    </label>

                    <h4>신청자 정보 입력</h4>

                    <label>
                        <p className="tit">신청자 이메일:</p>
                        <input
                            type="email"
                            placeholder="your.email@example.com"
                        />
                    </label>

                    <label>
                        <p className="tit">신청 사유:</p>
                        <input
                            type="email"
                            placeholder="신청 사유를 적어주세요."
                        />
                    </label>
                </div>
                <div className="application-btn">
                    <button>
                        신청
                    </button>
                    <button onClick={cancelHandler}>
                        취소
                    </button>
                </div>
            </div>
        </div>
    )
}

export  default ApplicationPage;
