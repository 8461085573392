import React, {useEffect, useState} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ko';  // Import the Korean locale
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './adminDataPage.css'
import {Link} from "react-router-dom";
import api from '../api';
function AdminDataPage() {

    const adminMenu = [
        {
            'name': "홈으로",
            'path': "/"
        },
        {
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage/adminDataPage"
        },
        {
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "데이터 제출 목록 일괄(고민중)",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmissionStatus/dataSubmissionStatus"
        },
        {
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "DMP 계획 및 제출 현황",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "DMP 접근권한 확인",
            'path': "/adminPage/dmpAccessCheck/dmpAccessCheck"
        },
    ]
    moment.locale('ko');

    const localizer = momentLocalizer(moment);

    const [events, setEvents] = useState([
        {
            id: Date.now(),
            start: moment().toDate(),
            end: moment().add(3, 'days').toDate(),
            title: '예제 공개데이터 저장',
        },
       
    ]);

    const [newEvent, setNewEvent] = useState({
        title: '',
        start: moment().format('YYYY-MM-DDTHH:mm'),
        end: moment().add(1, 'hours').format('YYYY-MM-DDTHH:mm'),
    });

    console.log("날짜 공개", newEvent.start);

    // const handleAddEvent = () => {
    //     const eventToAdd = {
    //         ...newEvent,
    //         start: new Date(newEvent.start),
    //         end: new Date(newEvent.end)
    //     };
    //     setEvents([...events, eventToAdd]);
    //     alert('등록 완료!!');
    // };

    //sendEmail

    useEffect(() => {
        api.get(`/api/dmp/sendmail`, {

        }, {
            params: {
                History_idx : "",
                Send_tp : "",
                To_user : "" ,
                Title : "타이틀테스트" ,
                Contents : "컨텐츠테스트",
                File : "" ,
                Insert_user_idx : "",
                Insert_dt : newEvent.start ,
                Send_count :  3,
                Sms_tp : "" ,
                Send_state : "전송중 " ,
                Del_is : ""
            }
        })
    },[])

    // 이벤트 클릭 핸들러
    const handleEventClick = (event) => {
        if (window.confirm(`'${event.title}' 이벤트를 삭제하시겠습니까?`)) {
            setEvents(events.filter(e => e.id !== event.id));
            alert('이벤트가 삭제되었습니다.');
        }
    };

    useEffect(() => {
        api.get(`/api/m/datastatus`).then((data) => console.log(data))
    }, []);


    return(
        <>
            <div className="adminpage-container">
                <h1>관리자 전용 메뉴입니다.</h1>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <div key={index} className="menu-item">
                                <Link to={item.path}>
                                    {item.name}
                                    <span className="arrow">▼</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className="check-calendar">
                        {/*<div className="calendar-container">*/}
                        {/*    <input*/}
                        {/*        type="text"*/}
                        {/*        placeholder="이벤트 제목"*/}
                        {/*        value={newEvent.title}*/}
                        {/*        onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}*/}
                        {/*    />*/}
                        {/*    <input*/}
                        {/*        type="datetime-local"*/}
                        {/*        value={newEvent.start}*/}
                        {/*        onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}*/}
                        {/*    />*/}
                        {/*    <input*/}
                        {/*        type="datetime-local"*/}
                        {/*        value={newEvent.end}*/}
                        {/*        onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}*/}
                        {/*    />*/}
                        {/*    <button>이벤트 추가</button>*/}
                        {/*</div>*/}
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            onSelectEvent={handleEventClick} // 이벤트 선택 시 핸들러 실행
                            style={{ height: 770 }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export  default AdminDataPage;