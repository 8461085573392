import React, {useEffect, useState} from "react";
import './dmplist.css'
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {useAllDmpData} from "../../stores/ImgDataSets";
import api from '../../api';

function Dmplist() {
    const [dmpList, setDmpList] = useState([]);
    const [dmpListHistory, setDmpListHistory] = useState("newHistory");
    const [listCount, setListCount] = useState(0);

    const navigate = useNavigate();

    //모든 dmp 상세 데이터는 담느다.
    const {setAllDmpDataSets} = useAllDmpData();

    useEffect(() => {
        if (dmpListHistory === "newHistory") {
            api.get(`/api/dmp/dmplist?kw&ps&pl`, {
                withCredentials: true
            }).then(res => {
                setDmpList(res.data.items);
                setListCount(res.data.pages.Total);
            }).catch(error => {
                console.error("Error fetching new history data:", error);
            });
        } else if (dmpListHistory === "historyData") {
            api.get(`/api/history/list?kw&ps&pl`, {
                withCredentials: true
            }).then(res => {
                console.log("데이터 확인", res.data.item);
                setDmpList(res.data.item);
                setListCount(res.data.pages.Total);
            }).catch(error => {
                console.error("Error fetching history data:", error);
            });
        }
    }, [dmpListHistory]); // dmpListHistory가 변경될 때마다 useEffect가 실행됩니다.

    const dmpRowClick = async (id) => {
        navigate('/dmpManagement/dmpcreate/dmpcreate');
        try{
            await axios.get(`http://localhost:8083/api/dmp/loaddmpall?dmpNo=${id}&PrdYear=2024`, { withCredentials: true })
                .then((res) => setAllDmpDataSets(res.data));
        }catch (err) {
            console.log("dmp 한번에 불러오기 에러 확인요망", err);
        }
    }

    console.log(listCount);

    return(
        <>
            <div className="dmplist-container">
                <div className="dmplist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmplist-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span>데이터 관리 계획</span>
                    </div>
                    <div className="dmplist-description">
                        <div className="dmplist-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > DMP 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmplist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                </div>
                <div>
                    <div className="btn-container">
                        <div className="history-select">
                            <div>총 {listCount}건</div>
                            <select
                                value={dmpListHistory}
                                onChange={(e) => setDmpListHistory(e.target.value)}>
                                <option value="newHistory">
                                    최신 데이터 조회
                                </option>
                                <option value="historyData">
                                    수정이력 조회
                                </option>
                            </select>
                        </div>
                        <div className="btn-check">
                            <button className="create02-dmp">
                                <Link to="/dmpobj/dmpObjCreate">
                                    과제 등록
                                </Link>
                            </button>
                            <button className="create02-dmp">
                                <Link to="/dmpManagement/dmpCreate/dmpcreate">
                                    DMP 등록
                                </Link></button>
                            <button className="send02-dmp">
                                <Link to="/dmpManagement/dmpSend/dmpsend">
                                    DMP 제출
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="dmplist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th className="obn01">과제번호</th>
                            <th className="obn02">연차</th>
                            <th className="obn03">과제명</th>
                            <th className="obn04">연구 책임자</th>
                            <th className="obn05">DMP 상태</th>
                            <th className="obn06">마지막수정일</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dmpList?.map((row, index) => (
                            row.DmpHisNo ? (
                                <tr key={index} className="dmplist-Data">
                                    <td>{row.ProjNo}</td>
                                    <td>{row.ProjAnnl}년차</td>
                                    <td>{row.ProjNm}</td>
                                    <td>{row.RsPnEmpNm}</td>
                                    <td>{row.SubmitStatus === '1' ? "저장" : "수정 중"}</td>
                                    <td>{row.HisInsDT}</td>
                                </tr>
                            ) :
                                (
                                    <tr key={index} className="dmplist-Data">
                                        <td>{row.ProjNo}</td>
                                        <td>{row.ProjAnnl}년차</td>
                                        <td
                                            style={{ cursor: 'pointer'}}
                                            onClick={() => dmpRowClick(row.DmpNo)}
                                        >
                                            {row.ProjNm}
                                        </td>
                                        <td>{row.RsPnEmpNm}</td>
                                        <td>{row.SubmitStatus === '1' ? "저장" : '수정 중' }</td>
                                        <td>{row.InsDt}</td>
                                    </tr>
                                )

                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export  default  Dmplist;