import React, {useEffect, useState} from "react";
import './dmpsend.css'
import api from '../../api';

function Dmpsend() {

    const [dmpsend, setDmpSend] = useState([]);
    const [dmpSendDmpNo, setDmpSendDmpNo] = useState([])

    useEffect(() => {
        api.get(`/api/dmp/dmplist?kw&ps&pl`, {
            withCredentials: true
        }).then(res =>{
                setDmpSend(res.data.items)

        });
    }, []);

    console.log(dmpsend)

    const dmpHwpDownload = (id) => {
        api.get(`/api/dmp/bjhtest?DmpNo=${id}`, {
            withCredentials: true,
            responseType: 'blob'  // 응답을 blob으로 받기 위해 설정
        })
            .then(res => {
                // 네트워크 응답 확인
                if (res.status !== 200) {
                    throw new Error('Failed to download file, status code: ' + res.status);
                }
                return res.data;  // axios에서는 blob 데이터가 res.data에 저장됩니다.
            })
            .then(blob => {
                // HWP 파일 다운로드 링크 생성
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'download.hwp'; // 파일명 설정
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a); // a 태그를 다운로드 후 제거
            })
            .catch(error => {
                console.error('Error downloading HWP file:', error);
                // 에러 처리 로직
            });
    }

    const dmpSendHandler = (id) => {
        api.put(`/api/dmp/dmpsubmit?DmpNo=${id}`).then((res => console.log(res.data)));
    }

    return(
        <>
            <div className="dmpsend-container">
                <div className="dmpsend-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpsend-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span>데이터 관리 계획(DMP 제출)</span>
                    </div>
                    <div className="dmpsend-description">
                        <div className="dmpsend-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > DMP 제출</span>
                        </div>
                    </div>
                </div>
                <div className="dmp-sendcontainer">
                    <div>
                        <div>총 4건</div>
                    </div>
                    <div className="dmpsend-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px' }}>
                        <thead>
                        <tr className="dmpsend-title">
                            <th>과제번호</th>
                            <th>연차</th>
                            <th>과제명</th>
                            <th>연구 책임자</th>
                            <th>DMP 문서 다운로드</th>
                            <th>DMP 제출상태</th>
                            <th>마지막 수정일</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dmpsend?.map((row, index) => (
                            <tr key={index} className="dmpsend-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.ProjAnnl}년차</td>
                                <td>{row.ProjNm}</td>
                                <td>{row.RsPnEmpNm}</td>
                                <td>
                                    <button className="download-button" onClick={() => dmpHwpDownload(row.DmpNo)}>다운로드</button>
                                </td>

                                <td>
                                    {
                                        row.SubmitStatus === '1' ?  <button className="dmpsend-btn">저장중</button> : <button className="dmpsend-btn" onClick={() => dmpSendHandler(row.DmpNo)}>dmp 제출하기</button>
                                    }
                                </td>
                                <td>{row.UpdDt}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Dmpsend;
