import React, {useEffect, useRef, useState} from "react";
import Grid from "@toast-ui/react-grid";
import '../toast/toastGrid.css';
import {useManagementDataStore} from "../stores/lastDmpData";
import {useNewDmpManagementPlan} from "../stores/newWriteDmp";

function DataManagementGrid({updateProductionPn}) {
    const gridRef = useRef(null);
    const [list, setList] = useState(() => []);
    const { updateManagementPlandataSet, addnewManagementPlandataSet } = useNewDmpManagementPlan();

    //연구데이터 관리 계획
    const {setManagementDataSets} = useManagementDataStore()

    useEffect(() => {
        if(updateProductionPn && updateProductionPn.Mnmt) {
            const addList = updateProductionPn.Mnmt.map(item => ({
                    DmpMgmtNo:item.DmpMgmtNo,
                    DmpNo:item.DmpNo,
                    dataType:item.DtaNm,
                    method:item.MngMth,
                    DmpPrdNo:item.DmpPrdNo,
                    individualManagement:item.MngResn,
                    dataLocation:item.MngPos,
                    storagePeriod:item.MngPd,
                    periodExpires:item.MngOpnMth,
                    DelFlg:item.DelFlg,
                    InsDt: item.InsDt,
                    UpdDt: item.UpdDt,
                    delete:'삭제',
                }))
            setList(addList);
        }
        else {
            setList([]); // 또는 초기값을 설정
        }
    }, [updateProductionPn]);


    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor: {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DATABASE_MI', value: 'DATABASE_MI' },
                        { text: 'PROJECT', value: 'PROJECT' },
                        { text: 'PROJECT_FILE', value: 'PROJECT_FILE' },
                        { text: 'NORMALIZATION', value: 'NORMALIZATION' }
                    ]
                }
            },
            width: 140,
        },
        {
            header: '관리방법',
            name: 'method',
            editor: {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DMP 플랫폼', value: 'DMP 플랫폼' },
                        { text: '개별관리', value: '개별관리' }
                    ]
                }
            },
            width: 140
        },
        {
            header: '개별관리사유',
            name: 'individualManagement',
            editor: 'text',
            width: 140
        },
        
        {
            header: '데이터보관 위치',
            name: 'dataLocation',
            editor: 'text',
            width: 140
        },
        {
            header: '보관기간',
            name: 'storagePeriod',
            editor: 'text',
            width: 140
        },
        {
            header: '보관기간 만료 후 관리 정책',
            name: 'periodExpires',
            editor: 'text',
            width: 210
        },
        {
            header: '행 삭제',
            name: 'delete',
            formatter: ({ row }) => `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        }
    ];
    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            dataType:'-',
            method:'-',
            individualManagement:'-',
            dataLocation:'-',
            storagePeriod:'-',
            periodExpires:'-',
        };
        setList(prevList => [...prevList, newRow]);
        addnewManagementPlandataSet(newRow);
    };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }
        setList(prevList => prevList.filter(row => row.rowKey !== parsedRowKey));
    };

    const handleGridUpdated = ({ changes }) => {
        changes.forEach(({ rowKey, columnName, value }) => {
            const newList = list.map((item, index) =>
                index === rowKey ? { ...item, [columnName]: value } : item
            );
            setList(newList);

            if (['dataType','method', 'individualManagement',  'dataLocation', 'storagePeriod', 'periodExpires'].includes(columnName)) {
                updateManagementPlandataSet(newList[rowKey].id, { [columnName]: value });
            }
        });
    };

    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });
        setManagementDataSets(list);

    }, [list]);


    return (
        <div className="toast-container">
            <div className="row-btn">
                <button className="public-data-addrow" onClick={handleAddRow}>
                    행추가
                </button>
            </div>
            <Grid
                data={list}
                columns={columns}
                ref={gridRef}
                rowHeight={'auto'}
                bodyHeight={300}
                columnOptions={{ resizable: true }}
                rowHeaders={['rowNum']}
                onAfterChange ={handleGridUpdated}
            />
        </div>
    );
}

export default DataManagementGrid;
