import React, {useEffect, useState, useRef} from 'react';
import './dmpcreate.css'
import DataManagementGrid from "../../dmpDataManagement/dataManagementGrid";
import LastDmpShow from "../../lastDmp/lastDmpShow";
import {
    useTaskDataStore,
    usePublicDataStore,
    useManagementDataStore,
    useProductionDataSetsStore,
    useToastSetStore,
    useLastDataStore, useToastSetMethodStore, useToastSetResultStore
} from "../../stores/lastDmpData";
import DataPublicGrid from "../../dmpDataPublic/dataPublicGrid";
import TaskCheck from "../../taskCheck/taskCheck";
import api from '../../api';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import DmpDataProductionPlan from "../../dmpDataProductionPlan/dmpDataProductionPlan";
import {
    useNewDmpManagementPlan,
    useNewDmpProductionPlan,
    useNewDmpPublicPlan,
    useNewDmpToastData
} from "../../stores/newWriteDmp";
import {useAllDmpData, useCallImgStore} from "../../stores/ImgDataSets";
import DmpToastGrid from "../../dmpToast/dmpToast";

function DmpcreatePage() {

    const navigate = useNavigate();

    //누락된 위치로 이동
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const objFocusNumber = useRef(null);
    const objFocusName = useRef(null);
    const objFocusLeader = useRef(null);


    // const [focusvalues, setFocusvalues] = useState({ field1: '', field2: '' , field3:"", field4:"", field5:"", field6:"", field7:"", field8:"" });


    //dmp 세트에 대한 모드 변경
    const [mode, setMode] = useState('create');

    const [showLastDmp , setShowLastDmp] = useState(false);
    const [showObjCheck, setShowObjCheck] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    // 데이터 세트 추가해주는 함수


    //새로운 summary 생성
    const [newDataSummary, setNewDataSummary] = useState([]);

    // 가져온 데이터 setData 입니다.
    const dataTypes = ["시료데이터","방법데이터","결과데이터"];

    //새로운 summary 제목에 필요한 버튼 생성
    const newDataType = newDataSummary[newDataSummary.length - 1]?.DtaSetNm;
    const currentNewTypeIndex = dataTypes.indexOf(newDataType);
    const newDataSummaryName = (currentNewTypeIndex + 1) % dataTypes.length;
    const resultSummaryType = dataTypes[newDataSummaryName];
    
    // 과제 선택 부분
    const tastdataSets = useTaskDataStore(state => state.taskdataSets);

    // const [objTestDate , setObjTestDate] = useState()
    //
    // useEffect(() => {
    //     if (tastdataSets.length > 0) {
    //         setObjTestDate(tastdataSets[0].ProjNo)
    //     }
    // }, [tastdataSets]);

    // 이미지 불러오기 부분
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/bg_file_select.png`);

    //직접 입력하는 과제명, 과제번호, 설명
    const [projNo, setProjNo] = useState('');
    const [projNm, setProjNm] = useState('');
    const [rspnEmpNm, setRspnEmpNm] = useState('');
    // 직접 연구 방법 요약을 입력할때
    const [methodSummary, setMethodSummary] = useState("");

    // 직접 연구데이터 분석 계획
    const [analysisPlan, setAnalysisPlan] = useState("");

    //새로운 공개 계획 데이터 가져오기
    const firstProplan = useNewDmpProductionPlan(state => state.newProductionPlandataSets);
    const newManagement = useNewDmpManagementPlan(state => state.newManagementPlandataSets);
    const newPublicPlan = useNewDmpPublicPlan(state => state.newPublicPlandataSets);
    
    //수정 시 데이터 가져오기
    const modifyResultProplan = useProductionDataSetsStore(state => state.productiondataSets);
    const modifyResultManagementplan = useManagementDataStore(state => state.managementdataSets);
    const modifyResultPublicPlan = usePublicDataStore(state => state.publicdataSets);

    const modifyMaterialDataSets = useToastSetStore(state => state.toastdataSets);
    const modifyMethodDataSets = useToastSetMethodStore(state => state.toastMethoddataSets);
    const modifyResultDataSets = useToastSetResultStore(state => state.toastResultdataSets);

/////////////////////////////////////////////////////////////////////////////// 지난 DMP 불러오기 코드 부분
    //등록 시 지난DMP 불러오기를 하면 과제 번호도 바껴야함 같이 전부 세팅 되어야한다.
    const lastDataCellValue = useLastDataStore(state => state.lastdataSets);
    // 지난 dmp 불러오기 클릭 시 과제 정보에 들어갈 변수 설정
    const [lastDataProjNo, setLastDataProjNo] = useState('');
    const [lastDataProjNm, setLastDataProjNm] = useState('');
    const [lastDataRspnEmpNm, setLastDataRspnEmpNm] = useState('');

    const [modifyMethodSummary, setModifyMethodSummary] = useState(lastDataCellValue.DmpMain?.RsrcSmm);
    const [lastDataAnalysisPlan, setLastDataAnalysisPlan] = useState(lastDataCellValue.DmpMain?.RsrcAnlyPlan)


    // lastDataCellValue가 변경될 때마다 상태 업데이트
    useEffect(() => {
        if (lastDataCellValue?.DmpMain) {
            setLastDataProjNo(lastDataCellValue.DmpMain.ProjNo || '');
            setLastDataProjNm(lastDataCellValue.DmpMain.ProjNm || '');
            setLastDataRspnEmpNm(lastDataCellValue.DmpMain.RspnEmpNm || '');
        }
    }, [lastDataCellValue]);



    const [modifySummaryData, setModifySummaryData] = useState([]);

    const lastSummaryItem = modifySummaryData[modifySummaryData.length - 1]?.Summary;
    const lastDataType = lastSummaryItem?.DtaSetNm;
    const currentTypeIndex = dataTypes.indexOf(lastDataType);
    const nextDataTypeIndex = (currentTypeIndex + 1) % dataTypes.length;
    const nextDataType = dataTypes[nextDataTypeIndex];

    //과제 선택에서 데이터 가져온 값 데이터 저장
    useEffect(() => {
        const lastItem = tastdataSets[tastdataSets.length - 1];

        if (lastItem) {
            setProjNo(lastItem.ProjNo);
            setProjNm(lastItem.ProjNm);
            setRspnEmpNm(lastItem.RspnEmpNm);
        }
    }, [tastdataSets]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }

    };

    // 핸들러 함수: 입력 필드의 변화를 관리
    const newSummaryhandleChange = (id, key, value) => {
        setNewDataSummary(prevData =>
            prevData.map(item =>
                item.id === id ? { ...item, [key]: value } : item
            )
        );
    };

    // 데이터 세트 추가 핸들러
    const handleCreateAddDataSet = () => {
        setNewDataSummary(prevData => [
            ...prevData,
            ...dataTypes.map(type => ({
                id: Date.now() + Math.random(), // 고유한 ID 생성
                DtaSetNm: type, // dataTypes 배열의 각 값을 할당
                DtaSetNmSub: '', // 초기값
                DtaNm: '', // 초기값
                DtaDs: '', // 초기값
                DmpFlg: "Y",
                DataSet: [],
            }))
        ]);
    };

    //새로운 데이터세트
    // const newToastTableData = useNewDmpToastData(state => state.newDmpToastDataSets);

    const handleAddDataSet = () => {
        if (modifySummaryData) {
            const newDataSets = dataTypes.map(type => ({
                Summary: {
                    id: Date.now() + Math.random(), // 고유한 ID 생성
                    DtaSetNm: type, // "시료데이터", "방법데이터", "결과데이터" 순서대로 추가
                    DtaSetNmSub: "",
                    DtaNm: "",
                    DtaDs: "",
                }
            }));

            setModifySummaryData([
                ...modifySummaryData,
                ...newDataSets // 새로 생성된 데이터 세트를 추가
            ]);
        }
    };


    const handleUpdateChange = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        const updatedDataSets = modifySummaryData.map(dataSet =>
            dataSet.Summary.DtaSetNo === id ? {...dataSet, Summary: {...dataSet.Summary, DtaSetNm: newValue }} : dataSet
        );

        setModifySummaryData(updatedDataSets);
    }

    const handleRequiredChange = (id, event) => {
        const newValue = event.target.value;
        const updatedDataSets = modifySummaryData.map(dataSet =>
            dataSet.Summary.DtaSetNo === id
                ? {
                    ...dataSet,
                    Summary: {
                        ...dataSet.Summary,
                        DtaSetNmSub: newValue, // DtaSetNmSub을 업데이트
                    },
                }
                : dataSet
        );
        setModifySummaryData(updatedDataSets);
    };

    const handleInputChange = (id, event) => {
        const newValue = event.target.value;
        const updatedDataSets = modifySummaryData.map(dataSet =>
            dataSet.Summary.DtaSetNo === id
                ? {
                    ...dataSet,
                    Summary: {
                        ...dataSet.Summary,
                        DtaNm: newValue, // DtaNm을 업데이트
                    },
                }
                : dataSet
        );
        setModifySummaryData(updatedDataSets);
    };

    const handleInputdescChange = (id, event) => {
        const newValue = event.target.value;
        const updatedDataSets = modifySummaryData.map(dataSet =>
            dataSet.Summary.DtaSetNo === id
                ? {
                    ...dataSet,
                    Summary: {
                        ...dataSet.Summary,
                        DtaDs: newValue, // DtaDs을 업데이트
                    },
                }
                : dataSet
        );
        setModifySummaryData(updatedDataSets);
    };
    // 삭제에 대한 id 구분 해야함
    const handleDeleteDataSet = (id) => {
        const updatedData = modifySummaryData.filter(dataSet => dataSet.Summary.DtaSetNo !== id);
        setModifySummaryData(updatedData); // 상태를 업데이트
    };

    const newHandleDeleteDataSet = (id) => {
        setNewDataSummary(newDataSummary.filter(dataSet => dataSet.id !== id));
    };

    // 지난 데이터 불러오기 컴포넌트

    const toggleLastDmp = () => {
        setShowLastDmp(!showLastDmp);
    }
    const toggleHistoryDmp = () => {
        setShowHistory(!showHistory);
    }

    const toggleObjCheck = () => {
        setShowObjCheck(!showObjCheck);
    }

    console.log("들어온 데이터 셑 값 확인 ======================", newDataSummary);


    const DataSaveBtn = () => {
            // new 에 해당 하는 데이터 값
            const productionPnNewData = firstProplan.map((newData) => ({
                PrdYear : "2024",
                DtaNm : newData.dataType,
                ProCnt : newData.count,
                ProPlan : newData.proPlan
            }))
            //새로운 management새로운 데이터
            const managePNNewData = newManagement.map((newData)=>({
                DtaNm : newData.dataType,
                MngMth : newData.method,
                MngResn : newData.individualManagement,
                MngPos : newData.dataLocation,
                MngPd : newData.storagePeriod,
                MngOpnMth : newData.periodExpires
            }))
            //공개 새로운 데이터
            const publicNewData = newPublicPlan.map((newData)=>({
                DtaNm : newData.datatype,
                OpnTm : newData.publictime,
                OpnPd : newData.externalTime,
                OpnMth : newData.publicmethod,
                OpnResn : newData.individualplan,
                OpnOpnMth : newData.individualmethod
            }))

//             const createDataSet = (dataSets) => dataSets.map((data) => ({
//                 DtaFld: data.field,
//                 DtaTyp: data.type,
//                 DtaUnit: data.unit,
//                 DtaMtdIdx: data.index,
//                 DtaMtdNm: data.name,
//                 DtaDsc: data.description || data.methoddescription,
//                 UserNo: data.UserNo,
//                 UserNm: data.UserNm,
//             }));
//
// // 각 데이터셋에 대해 배열 생성
//             const setDataFieldPn = createDataSet(modifyResultDataSets);
//             const setDataMethodPn = createDataSet(modifyMethodDataSets);
//             const setDataMaterialPn = createDataSet(modifyMaterialDataSets);


            //신규 저장 시료데이터
        // 시료데이터 저장
        // const materialDataSetValues = newDataSummary
        //     .map((dataItem) => ({
        //         DtaSetNm: dataItem.DtaSetNm,
        //         DtaSetNmSub: dataItem.DtaSetNmSub,
        //         DtaNm: dataItem.DtaNm,
        //         DtaDs: dataItem.DtaDs,
        //         DmpFlg: dataItem.DmpFlg,
        //         DataMain: dataItem.DataSet.map((item) => ({
        //             DtaFld: item.field,
        //             DtaTyp: item.type,
        //             DtaUnit: item.unit,
        //             DtaMtdIdx: item.index, // `data.index`를 `item.index`로 수정
        //             DtaMtdNm: item.name, // `daitemitemta.name` 오타 수정
        //             DtaDsc: item.description || item.methoddescription,
        //             UserNo: item.UserNo,
        //             UserNm: item.UserNm
        //         }))
        //     }));

        const materialDataSetValues = newDataSummary.map((dataItem) => ({
            DtaSetNm: dataItem.DtaSetNm,
            DtaSetNmSub: dataItem.DtaSetNmSub,
            DtaNm: dataItem.DtaNm,
            DtaDs: dataItem.DtaDs,
            DmpFlg: dataItem.DmpFlg,
            DataMain: dataItem.DataSet.map((item) => ({
                DtaFld: item.field,
                DtaTyp: item.type,
                DtaUnit: item.unit,
                DtaMtdIdx: item.index,
                DtaMtdNm: item.name,
                DtaDsc: item.description || item.methoddescription,
                UserNo: item.UserNo,
                UserNm: item.UserNm
            }))
        }));



        console.log("materialDataSetValues:", materialDataSetValues);

// 방법데이터에 해당하는 데이터만 필터링 및 첫 번째 요소를 반환

//             //신규 저장 방법데이터
//             const methodDataSetValue = newDataSummary
//                 .filter((dataItem) => dataItem.DtaSetNm === "방법데이터" && dataItem.id )
//                 .map((dataItem) => ({
//                     DtaSetNm: dataItem.DtaSetNm,
//                     DtaSetNmSub: dataItem.DtaSetNmSub,
//                     DtaNm: dataItem.DtaNm,
//                     DtaDs: dataItem.DtaDs,
//                     DmpFlg: dataItem.DmpFlg,
//                     DataMain: setDataMethodPn // 방법데이터에 해당하는 배열만 들어가게 설정
//                 }))[0] || {}; // 첫 번째 요소 반환, 없으면 빈 객체
//
// // 결과데이터에 해당하는 데이터만 필터링 및 첫 번째 요소를 반환
//
//             //신규 저장 결과데이터
//
//             const resultDataSetValue = newDataSummary
//                 .filter((dataItem) => dataItem.DtaSetNm === "결과데이터" && dataItem.id)
//                 .map((dataItem) => ({
//                     DtaSetNm: dataItem.DtaSetNm,
//                     DtaSetNmSub: dataItem.DtaSetNmSub,
//                     DtaNm: dataItem.DtaNm,
//                     DtaDs: dataItem.DtaDs,
//                     DmpFlg: dataItem.DmpFlg,
//                     DataMain: setDataFieldPn // 결과데이터에 해당하는 배열만 들어가게 설정
//                 }))[0] || {}; // 첫 번째 요소 반환, 없으면 빈 객체


            axios.post('http://localhost:8083/api/dmp/saveDmp',
                {
                    DmpMain:
                        {
                            ProjNo: tastdataSets[0].ProjNo,
                            ProjNm: tastdataSets[0].ProjNm,
                            RspnEmpNo: tastdataSets[0].RspnEmpNo,
                            RspnEmpNm: rspnEmpNm,
                            DataEmpNo: tastdataSets[0].DataEmpNo,
                            DataEmpNm: tastdataSets[0].DataEmpNm,
                            RsrcSmm: methodSummary,
                            RsrcImg: "",
                            RsrcFileNm: "",
                            RsrcFilePath: "",
                            RsrcAnlyPlan: analysisPlan,
                            TmpYn: "N"
                        },

                    Summary:

                            materialDataSetValues,
                    Prod: productionPnNewData,
                    Mnmt:managePNNewData,
                    Open:publicNewData,

                },{withCredentials: true }).then((res) => {
                alert(res.data);
                navigate("/dmpManagement/dmpList/dmplist")
            }).catch((error) => {
                console.error("API 통신 에러 발생: ", error.response ? error.response.data : "No response data");
                alert("데이터 전송이 불가능 합니다. 빠진부분을 채워주세요.")
            });

    }

    return(
        <>
            <div className="dmp-banner-container">
                <div className="dmp-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmp-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span>데이터 관리 계획</span>
                    </div>
                    <div className="dmp-description">
                        <div className="dmp-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > 데이터 관리 계획 등록</span>
                        </div>
                    </div>
                </div>


                <section>
                    <div className="management-plan-title">
                        <h1>데이터 관리 계획</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="object-info">
                        <div className="object-info-title">
                            <span className="info">과제 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="task-title">
                            <div className="task-wrapper">
                                    <>
                                        <button className="check-obj" onClick={toggleObjCheck}>과제 선택</button>
                                        {showObjCheck && <TaskCheck />}
                                    </>

                                {
                                    lastDataCellValue.DmpMain ? (
                                            // lastDataCellValue가 있을 경우, 해당 데이터를 읽어 readOnly 상태로 보여줌
                                            <div className="objCheck-name" key={lastDataCellValue.DmpMain.DmpNo}>
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    value={lastDataProjNo}
                                                    onChange={(e) => setLastDataProjNo(e.target.value)}
                                                    placeholder="과제번호"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    value={lastDataProjNm}
                                                    onChange={(e) => setLastDataProjNm(e.target.value)}
                                                    placeholder="과제명"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    value={lastDataRspnEmpNm}
                                                    onChange={(e) => setLastDataRspnEmpNm(e.target.value)}
                                                    placeholder="연구책임자"
                                                />
                                            </div>
                                        ):

                                        tastdataSets.length > 0 ? (
                                    // tastdataSets에 데이터가 있을 경우, 그 데이터를 읽어 readOnly 상태로 보여줌
                                    <div key={tastdataSets[0].DmpNo} className="objCheck-name">
                                        <input
                                            type="text"
                                            className="edit--box"
                                            value={tastdataSets[0].ProjNo || ''}
                                            readOnly
                                            placeholder="과제번호"
                                        />
                                        <input
                                            type="text"
                                            className="edit--box"
                                            value={tastdataSets[0].ProjNm || ''}
                                            readOnly
                                            placeholder="과제명"
                                        />
                                        <input
                                            type="text"
                                            className="edit--box"
                                            value={tastdataSets[0].RspnEmpNm || ''}
                                            readOnly
                                            placeholder="연구책임자"
                                        />
                                    </div>
                                ) : (
                                    // 모든 조건이 만족되지 않을 경우 빈 입력 창을 보여줌
                                    <>
                                        <input
                                            type="text"
                                            className="edit--box"
                                            value={ ''}
                                            placeholder="과제번호"
                                        />
                                        <input
                                            type="text"
                                            className="edit--box"
                                            value={''}
                                            placeholder="과제명"
                                        />
                                        <input
                                            type="text"
                                            className="edit--box"
                                            value={''}
                                            placeholder="연구책임자"
                                        />
                                    </>
                                )}
                                    <>
                                        <button className="last-dmp" onClick={toggleLastDmp}>지난 DMP 불러오기</button>
                                        {showLastDmp && <LastDmpShow />}
                                        <button className="history-dmp" onClick={toggleHistoryDmp}>임시저장 DMP 불러오기</button>
                                        {showHistory && <LastDmpShow setShowHistory = {showHistory}/>}
                                    </>

                            </div>
                            <div>
                                <span>* 과제 번호를 선택하면 과제 정보가 자동으로 입력됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                        <div className="research-method-info">
                            <div className="object-info-title">
                                <span className="info">연구 방법 요약</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="research-method-title">
                                <textarea
                                    type="text"
                                    ref={inputRef1}
                                    value={lastDataCellValue ? modifyMethodSummary : methodSummary}
                                    onChange={(e) => lastDataCellValue > 0 ? setModifyMethodSummary(e.target.value) : setMethodSummary(e.target.value)}
                                    className="edit--box"
                                    placeholder="연구방법을 서술형으로 작성하세요."
                                />
                            </div>
                        </div>
                            <div className="start_line"></div>
                            <div className="configuring-research-data-info">
                                <div className="object-info-title">
                                    <span className="info">연구데이터 구성</span>
                                </div>
                                <div className="research-method-title">
                                    <label htmlFor="fileDiagram">
                                        <img id="imgDiagram" src={imageSrc} alt="Diagram" />
                                    </label>
                                    <input
                                        type="file"
                                        id="fileDiagram"
                                        name="fileDiagram"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                    <input type="text" id="txtDiagram" name="txtDiagram" style={{ display: 'none' }} />

                                </div>
                            </div>
                            <div className="start_line"></div>

                            <div className="data-set-container" >
                                {newDataSummary.map((newSetData, index) => (
                                    <div className="data-set-info" key={index}>
                                        <div className="data-set-title-wrapper">
                                            <div className="data-set-title">
                                                <span className="info">데이터 세트</span>
                                                <span className="required_color">*</span>
                                                <button className="data-set-delete" onClick={() => newHandleDeleteDataSet(newSetData.id)} >
                                                    <span>-</span>
                                                    <span>삭제</span>
                                                </button>
                                            </div>
                                            <div className="selectbox-container">
                                                <div className="selectbox-check">
                                                    <select
                                                        className="resultData"
                                                        value={newSetData.DtaSetNm}
                                                        onChange={(e) => newSummaryhandleChange(newSetData.id, 'DtaSetNm', e.target.value)}
                                                    >
                                                        <option value=''>-- 구분 --</option>
                                                        <option value="시료데이터">시료데이터</option>
                                                        <option value="방법데이터">방법데이터</option>
                                                        <option value="결과데이터">결과데이터</option>
                                                    </select>
                                                    <select
                                                        className="resultData"
                                                        id="selectBox"
                                                        value={newSetData.DtaSetNmSub}
                                                        onChange={(e) => newSummaryhandleChange(newSetData.id, 'DtaSetNmSub', e.target.value)}
                                                    >
                                                        <option value=''>-- 선택 * 필수 --</option>
                                                        <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                        <option value="Food, Processed">Food, Processed</option>
                                                        <option value="Food, Derived">Food, Derived</option>
                                                        <option value="Animal">Animal</option>
                                                        <option value="Human">Human</option>
                                                        <option value="Invertebrate">Invertebrate</option>
                                                        <option value="Microbe">Microbe</option>
                                                        <option value="Cell, Animal">Cell, Animal</option>
                                                        <option value="Cell, Human">Cell, Human</option>
                                                        <option value="Cell, Plant">Cell, Plant</option>
                                                        <option value="etc">etc</option>
                                                    </select>

                                                    <input
                                                        type="text"
                                                        className="edit--box"
                                                        value={newSetData.DtaNm}
                                                        onChange={(e) => newSummaryhandleChange(newSetData.id, 'DtaNm', e.target.value)}
                                                        id="data-type-name"
                                                        name="DtaNm"
                                                        placeholder="데이터 타입명"
                                                    />
                                                    <input type="text"
                                                           className="edit--box"
                                                           value={newSetData.DtaDs}
                                                           onChange={(e) => newSummaryhandleChange(newSetData.id, 'DtaDs', e.target.value)}
                                                           id="data-type-desc"
                                                           name="DtaDs"
                                                           placeholder="데이터 타입 설명"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="toastui-wrapper">
                                            < DmpToastGrid MethodData={newSetData.DtaSetNm} DateSets={newSetData}/>
                                        </div>
                                    </div>
                                ))}
                                <button className="data-set-add" onClick={handleCreateAddDataSet}>{`+ ${resultSummaryType} 세트 양식 추가`}</button>
                            </div>
                    <div className="start_line"></div>
                    <div className="data-management-info">
                        <div className="object-info-title">
                            <span className="info">생산 계획</span>
                            <span className="required_color">*</span>
                        </div>
                            <div>
                                <DmpDataProductionPlan/>
                            </div>

                         </div>
                    <div className="start_line"></div>

                    <div className="data-analysis-info">
                        <div className="object-info-title">
                            <span className="info">연구데이터 분석 계획</span>
                            <span className="required_color">*</span>
                        </div>

                                <div className="research-method-title">
                                    <textarea type="text"
                                              ref={inputRef2}
                                              className="edit--box"
                                              value={analysisPlan}
                                              onChange={(e) => setAnalysisPlan(e.target.value)}
                                              placeholder="연구데이터 분석 계획을 서술형으로 작성하세요."></textarea>
                                </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="data-management-info">
                        <div className="object-info-title">
                            <span className="info">연구데이터 관리 계획</span>
                            <span className="required_color">*</span>
                        </div>
                            <div>
                                <DataManagementGrid/>
                            </div>
                    </div>
                    <div className="start_line"></div>

                    <div className="data-public-info">
                        <div className="object-info-title">
                            <span className="info">연구데이터 공개 계획</span>
                            <span className="required_color">*</span>
                        </div>
                            <div>
                                <DataPublicGrid/>
                            </div>
                    </div>
                    <div className="start_line"></div>
                </section>

                <div className="save-container">

                        <button id="dmp_list">
                            <Link to="/dmpManagement/dmpList/dmplist">목록</Link>
                        </button>

                    <button id="save-btn" onClick={DataSaveBtn}>
                        저장
                    </button>
                </div>

            </div>
        </>
    )
}
export default DmpcreatePage;