import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function DmpAccessCheck() {
    const [selectedYear, setSelectedYear] = useState("all");

    // 초기 데이터 설정
    const tableData = [
        {
            id: 1,
            DmpNo: 100,
            year: "2023",
            projectName: "Project A",
            yearData: "yearData",
            lead: "Test계정1",
            startyear: "2024-04-21",
            endyear: "2024-04-30",
            access: "xxx",
        },
        {
            id: 2,
            DmpNo: 101,
            year: "2023",
            projectName: "Project B",
            yearData: "yearData",
            lead: "Test계정2",
            startyear: "2024-04-21",
            endyear: "2024-04-30",
            access: "xxx",
        },
    ];

    const [filteredDataes, setFilteredDataez] = useState(tableData);

    // access 값을 변경하는 함수
    const handleAccessChange = (id, newAccessValue) => {
        setFilteredDataez((prevData) =>
            prevData.map((row) =>
                row.id === id ? { ...row, access: newAccessValue } : row
            )
        );
    };

    // selectedYear가 변경될 때마다 데이터를 필터링하는 효과 추가
    useEffect(() => {
        const filtered = selectedYear === "all"
            ? tableData
            : tableData.filter((row) => row.year === selectedYear);
        setFilteredDataez(filtered);
    }, [selectedYear]);

    const adminMenu = [
        { name: "홈으로", path: "/" },
        { name: "캘린더(공개시기 관리)", path: "/adminPage/adminDataPage" },
        { name: "데이터 제출 현황", path: "/adminPage/submintStatus/submintStatus" },
        {
            name: "데이터 제출 목록 일괄(고민중)",
            path: "/adminPage/submintStatus/submintStatus",
        },
        {
            name: "데이터 제출 상태 변경",
            path: "/adminPage/dataSubmissionStatus/dataSubmissionStatus",
        },
        {
            name: "DMP 작성 현황 및 제출 상태 변경",
            path: "/adminPage/submintStatus/submintStatus",
        },
        { name: "DMP 계획 및 제출 현황", path: "/adminPage/submintStatus/submintStatus" },
        { name: "DMP 접근권한 확인", path: "/adminPage/dmpAccessCheck/dmpAccessCheck" },
    ];

    return (
        <>
            <div className="adminpage-container">
                <h1>관리자페이지(DMP 접근권한 확인)</h1>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <div key={index} className="menu-item">
                                <Link to={item.path}>
                                    {item.name}
                                    <span className="arrow">▼</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className="year-name">
                        <div>
                            과제수행년도
                            <select
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                <option value="all">전체</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                        </div>
                        <div className="btn-submissionStatus">
                            <button className="submission03-data">접근권한 수정</button>
                        </div>
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>과제번호</th>
                                <th>과제수행년도</th>
                                <th>과제명</th>
                                <th>연책</th>
                                <th>데이터담당</th>
                                <th>연차시작일</th>
                                <th>연차종료일</th>
                                <th>접근권한자</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredDataes.map((row) => (
                                <tr key={row.id} className="dmplist-Data">
                                    <td>{row.DmpNo}</td>
                                    <td>{row.year}</td>
                                    <td>{row.projectName}</td>
                                    <td>{row.yearData}</td>
                                    <td>{row.lead}</td>
                                    <td>{row.startyear}</td>
                                    <td>{row.endyear}</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={row.access}
                                            onChange={(e) =>
                                                handleAccessChange(row.id, e.target.value)
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DmpAccessCheck;
