import React, { useState } from 'react';
import './login.css';
import api from '../api';
import { useNavigate  } from "react-router-dom";
import useLoginAuthStore from "../stores/loginAuth";
const LoginPage = () => {
    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate ();

    const setUserId = useLoginAuthStore((state)=> state.setUserId);


    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post(`/api/auth/login`, {
                UserId: userid,
                UserPw: password
            }
            , {withCredentials: true }
            ).then((res) => res.data);
            setUserId(response);
            console.log(response);
            navigate('/');


        } catch (error) {
            alert("잘못된 정보입니다.")
            console.error('Login failed', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-logo">
                <img src={`${process.env.PUBLIC_URL}/CI_KFRI.png`} alt="KFRI Logo" />
            </div>

            <div className="login-line"></div>

            <form className="login-form" onSubmit={handleLogin}>
                <div className="form-group">
                    <label htmlFor="username">아이디</label>
                    <input
                        type="text"
                        id="username"
                        value={userid}
                        onChange={(e) => setUserid(e.target.value)}
                        placeholder="아이디를 입력하세요"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">비밀번호</label>
                    <div className="password-input">
                        <input
                            type='password'
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="비밀번호를 입력하세요"
                            required
                        />
                    </div>
                </div>
                <button type="submit"  className="login-button">
                    로그인
                </button>
            </form>
        </div>
    );
};

export default LoginPage;