import React, {useState} from "react";
import {Link} from "react-router-dom";
import './dataSubmissionStatus.css';

function DataSubmissionStatus() {

    const [selectedYear, setSelectedYear] = useState('all');
    const [checkedItems, setCheckedItems] = useState({});
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

    const adminMenu = [
        {
            'name': "홈으로",
            'path': "/"
        },
        {
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage/adminDataPage"
        },
        {
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "데이터 제출 목록 일괄(고민중)",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmissionStatus/dataSubmissionStatus"
        },
        {
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "DMP 계획 및 제출 현황",
            'path': "/adminPage/submintStatus/submintStatus"
        },
        {
            'name': "DMP 접근권한 확인",
            'path': "/adminPage/dmpAccessCheck/dmpAccessCheck"
        },
    ]
    const tableData = [
        { DtaSbmSeqNo : 100, id: 1, year: '2023', projectName: 'Project A', lead: 'Test계정1', dmpStatus: '허용', lastModified: '3', subMissionStatus: '제출 중'},
        { DtaSbmSeqNo : 101, id: 2, year: '2023', projectName: 'Project B', lead: 'Test계정2', dmpStatus: '허용', lastModified: '4', subMissionStatus: '제출 중' },
        { DtaSbmSeqNo : 102, id: 3, year: '2023', projectName: 'Project B', lead: 'Test계정3', dmpStatus: '불가', lastModified: '3', subMissionStatus: '제출 중' },
        { DtaSbmSeqNo : 103, id: 4, year: '2021', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '1', subMissionStatus: '제출 중'},
        { DtaSbmSeqNo : 104, id: 5, year: '2021', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '2', subMissionStatus: '제출 중'},
        { DtaSbmSeqNo : 105, id: 6, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3', subMissionStatus: '제출 중'},
        { DtaSbmSeqNo : 106, id: 7, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3', subMissionStatus: '제출 중'},
        { DtaSbmSeqNo : 107, id: 8, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3', subMissionStatus: '제출 중'},
        { DtaSbmSeqNo : 108, id: 9, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '3', subMissionStatus: '제출 중'},
        { DtaSbmSeqNo : 109, id: 10, year: '2022', projectName: 'Project B', lead: 'Jane Smith', dmpStatus: 'In Progress', lastModified: '4', subMissionStatus: '제출 중' },
    ];

    const filteredData = selectedYear === 'all'
        ? tableData
        : tableData.filter(row => row.year === selectedYear);


    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setIsSelectAllChecked(isChecked);
        const newCheckedItems = {};
        filteredData.forEach(row => {
            newCheckedItems[row.id] = isChecked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: isChecked,
        }));
    };

    const handleSubmitStatusChange = () => {
        const updatedTableData = tableData.map(row => {
            if (checkedItems[row.id]) {
                return { ...row, dmpStatus: 'Submitted' }; // Update submission status
            }
            return row;
        });
        // Here you would typically update the state or send the updated data to a server
    };

    return(
        <>
            <div className="adminpage-container">
                <h1>관리자페이지(데이터 제출 상태 변경)</h1>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <div key={index} className="menu-item">
                                <Link to={item.path}>
                                    {item.name}
                                    <span className="arrow">▼</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className='btn-submissionStatus-container'>
                        <div className='btn-submissionStatus'>
                            <button onClick={handleSubmitStatusChange}
                            className="submission02-data">제출상태 변경</button>
                            <button className="submission03-data">수정가능여부 확인</button>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>
                                    <input type="checkbox"
                                           className="row-checkboxSize"
                                           checked={isSelectAllChecked}
                                           onChange={handleSelectAllChange}
                                    />
                                </th>
                                <th>DtaSbmSeqNo</th>
                                <th>과제번호</th>
                                <th>과제수행년도</th>
                                <th>과제명</th>
                                <th>데이터세트</th>
                                <th>연책</th>
                                <th>제출자</th>
                                <th>제출상태</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData.map((row) => (
                                <tr key={row.id} className="dmplist-Data">
                                    <td>
                                        <input
                                            type="checkbox"
                                            className="row-checkboxSize"
                                            checked={checkedItems[row.id] || false}
                                            onChange={(e) => handleCheckboxChange(e, row.id)}
                                        />
                                    </td>
                                    <td>{row.DtaSbmSeqNo}</td>
                                    <td>{row.id}</td>
                                    <td>{row.year}</td>
                                    <td>{row.projectName}</td>
                                    <td>{row.lead}</td>
                                    <td>{row.dmpStatus}</td>
                                    <td>{row.lastModified}</td>
                                    <td>{row.subMissionStatus}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DataSubmissionStatus;