import React, {useState, useEffect} from "react";
import './dmpRealDataCreate.css';
import {Link} from "react-router-dom";
import ToastGrid from "../../toast/toastGrid";
import RegisterDmp from "../../registerDmp/registerDmp";
import {
    useRegisterDataStore,
    useImportDataSetsStore,
    useToastSetStore,
    useToastSetResultStore, useToastSetMethodStore
} from "../../stores/lastDmpData";
import ImportDataSet from "../../importDataSet/importDataSet";
import api from '../../api';
import {
    useDataSetsToastStore,
    useDataSummaryToastFilterStore,
    useDataSummaryToastStore
} from "../../stores/dataSetsStore";
import {useCallImgStore} from "../../stores/ImgDataSets";
import { useParams } from 'react-router-dom';

function DmpRealDataCreate() {



    // 초기 상태값 설정
    const initialVisibility = 'private';
    const initialStartDate = '';
    const initialEndDate = '';
    const initialEmail = '';
    const initialFiles = [];
    const initialShowCallImgData = [];
    const initialDataPageSummaryData = [];

    // 상태값
    const [visibility, setVisibility] = useState(initialVisibility);
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [email, setEmail] = useState(initialEmail);
    const [files, setFiles] = useState(initialFiles);
    const [showCallImgData, setShowCallImgData] = useState(initialShowCallImgData);
    const [dataPageSummaryData, setDataPageSummaryData] = useState(initialDataPageSummaryData);

    const [mode, setMode] = useState('create');

    // 초기화 함수
    const resetForm = () => {
        setVisibility(initialVisibility);
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
        setEmail(initialEmail);
        setFiles(initialFiles);
        setShowCallImgData(initialShowCallImgData);
        setDataPageSummaryData(initialDataPageSummaryData);
    };

    //등록 클릭 시 crate를 해줘야함 ~
    const [callImgData, setCallImgData] = useState({});



    //데이터 리스트 클릭 시 전체 데이터 불러오기
    // const callImgData = useCallImgStore(state => state.imgDataSets);

    console.log("callImgDatacallImgData@", callImgData);



    useEffect(() => {
        if(callImgData.data?.length > 0 ) {
            setMode('update');
        }
    }, []);

    console.log("callImg2Data", callImgData);


    //새로운 데이터 세트 만들떄 사용!!
    // const newToastTableData = useNewDmpToastData(state => state.newDmpToastDataSets);

    //데이터 세트 불러오는 전역 변수
    const callToastDataSets = useDataSetsToastStore(state => state.callToastDataSets);
    const callSummaryDataSets = useDataSummaryToastStore(state => state.callSummaryDataSets);

    const [showRegisterDmpCheck, setShowRegisterDmpCheck] = useState(false);
    const [showImportData, setShowImportData] = useState(false);



    const [sendImgFile ,setSendImgFile] = useState([]);
    const [_, setUploadData] = useState([])

    //new 작성 시 등록 대상 데이터에 들어가는 값입력 칸 (아에 새로작성하는 데이터세트)
    const [dataSetFirst , setdataSetFirst] = useState("");
    const [dataSetType , setDateSetType] = useState("");
    const [dataSetName , setDateSetName] = useState("");
    const [dataSetDES , setDataSetDES] = useState("");



    // 여기서 부터 신규 데이터 등록 변수 정리=====================================================
    // 등록 DMP 불러오기
    const registerdataSets = useRegisterDataStore(state =>state.registerdataSets);


    //DMP 데이터 세트(Summary)를 말함 불러오기
    const callSummaryFilterDataSets = useDataSummaryToastFilterStore(state => state.callSummaryFilterDataSets);

    //DMP 데이터세트 불러오기 클릭시 (new)

    useEffect(() => {
        setDataPageSummaryData(callSummaryFilterDataSets);
    },[callSummaryFilterDataSets])


    //데이터 세트 변경 변수 선언
    const DataPageMatarialDataSets = useToastSetStore(state => state.toastdataSets);
    const DataPageMethodDataSets = useToastSetMethodStore(state => state.toastMethoddataSets);
    const DataPageResultDataSets = useToastSetResultStore(state => state.toastResultdataSets);


    console.log("신규 데이터 세트 확인", DataPageResultDataSets, DataPageMethodDataSets, DataPageMatarialDataSets);

    const handleUpdateDataPageChange = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaSetNm: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }

    const handleUpdateRequierChange = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaSetNmSub: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }

    const handleDataPageInputChange = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaNm: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }

    const handleDataPageDES = (id, event) => {
        event.preventDefault(); // 이벤트의 기본 행동을 방지합니다.
        const newValue = event.target.value; // 사용자가 선택한 새로운 값을 가져옵니다.

        const updatedDataSets = dataPageSummaryData.map(dataSet =>
            dataSet.DtaSetNo === id ? { ...dataSet, DtaDs: newValue } : dataSet
        );

        setDataPageSummaryData(updatedDataSets);
    }

    //파일을 불러온 후 가공 === 불러오기단계임
    useEffect(() => {

        // 각 파일에 대해 DtaFilePath 추출
        const updatedFileList = callImgData.file?.map(item => {
            const decodedPath = decodeURIComponent(item.DtaSaveFileNm);
            const startIndex = decodedPath.indexOf("C:"); // 파일 경로의 시작 인덱스
            const DtaFilePath = startIndex !== -1 ? decodedPath.substring(startIndex) : null;

            return {
                ...item,
                DtaFilePath: DtaFilePath
            };
        });


        // 상태 업데이트
        setShowCallImgData(updatedFileList);
    }, [callImgData]); // callImgData가 변경될 때만 실행

    const toggleObjCheck = () => {
        setShowRegisterDmpCheck(!showRegisterDmpCheck);

        if(mode === 'update') {
            setShowRegisterDmpCheck(showRegisterDmpCheck);
            alert("수정에선 불러오기가 불가능합니다.");
        }
    }

    const toggleImportDataSets = () => {
        setShowImportData(!showImportData);
        if(mode === 'update') {
            setShowImportData(showImportData);
            alert("수정에선 데이터세트 불러오기가 불가능합니다.");
        }
    }


    //신규 데이터 등록에 필요한 로직
    const handleFileChange = (event) => {
        if (Array.isArray(showCallImgData) && showCallImgData.length > 0) {
            // 기존 파일 목록에 새로운 파일들을 추가
            setShowCallImgData((prev) => [...prev, ...Array.from(event.target.files)]);
        } else {
            setFiles((prev)=> [...prev, ...Array.from(event.target.files)]);
        }
    };


    const handleUpload = async () => {
        if (files.length === 0 && showCallImgData.length === 0) {
            alert('파일을 먼저 선택해주세요.');
            return;
        }
        if(mode === 'create') {
            const formData = new FormData();

            // 실제 파일 데이터를 FormData에 추가
            files.forEach((file) => {
                formData.append('files', file);
            });
            try {
                const response = await api.post('/api/file/uploadfiles', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // 필요한 경우 추가 설정
                    },
                });
                alert('files saved successfully!');
                setSendImgFile(prev => [...prev, ...response.data]);
                setUploadData(response.data);

            } catch (error) {
                console.error('Error uploading file:', error);
                alert('파일이 업로드 되지 않았습니다.');
            }
        }
        else if(mode === 'update') {

            // const noUploadDate = showCallImgData.filter((item, index) => item.type)


            const formData = new FormData();

            // 실제 파일 데이터를 FormData에 추가
            showCallImgData.forEach((file) => {
                formData.append('files', file);
            });

            try {
                const response = await api.post('/api/file/uploadfiles', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // 필요한 경우 추가 설정
                    },
                });
                alert('Files saved successfully!');

                // type이 있는 파일들을 showCallImgData에서 제거
                const updatedShowCallImgData = showCallImgData.filter((item) => !item.type);

                // response.data에 있는 파일을 새로운 형식으로 변환하여 추가
                const newFiles = response.data.map((file) => ({
                    ...file,
                    Fstatus: "save",
                    DtaSbmSeqNo : callImgData.dmpSummary.DtaSbmSeqNo,
                }));


                // 상태 업데이트: type이 없는 기존 파일들 + 새로운 파일들
                setShowCallImgData([...updatedShowCallImgData, ...newFiles]);


            } catch (error) {
                console.error('Error uploading file:', error);
                alert('수정부분에서 업로드 할 파일이 없습니다.');
            }

        }
    };
    // 상태 업데이트 후 로그를 확인하기 위해 useEffect 사용
    useEffect(() => {
    }, [showCallImgData]);

    const closeFile = (indexRemoveFile) => {
        if( mode === 'create') {
            setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexRemoveFile));

            const removeUploadFile = setUploadData((prevFiles)=> prevFiles.filter((item, index) => {
                if (index === indexRemoveFile) {
                    return (item.SaveFile);
                }
            }));

            if(removeUploadFile !== undefined) {
                api.post(`/api/file/deleteFile`, {
                    SaveFile : removeUploadFile,
                })
                alert("업로드 된 파일 삭제!!");
            }
        }
        else if (mode === 'update') {
            const fileToRemove = showCallImgData[indexRemoveFile];

            // 파일을 물리적으로 삭제하지 않고 상태를 업데이트
            setShowCallImgData((prevFiles) =>
                prevFiles.map((item, index) => {
                    if (index === indexRemoveFile) {
                        return {
                            ...item,
                            Fstatus: "delete", // 삭제 상태 추가
                        };
                    }
                    return item;
                })
            );
            console.log("삭제 후 데이터 확인 ", showCallImgData);
            alert("삭제되었습니다.")
        }
    }

    //삭제 이후 데이터 제출 렌더링
    useEffect(() => {
    }, [showCallImgData]);


    const getSelectedDataSets = (DtaSetNm) => {
        if (DtaSetNm === '시료데이터') {
            return DataPageMatarialDataSets;
        } else if (DtaSetNm === '방법데이터') {
            return DataPageMethodDataSets;
        } else if (DtaSetNm === '결과데이터') {
            return DataPageResultDataSets;
        }
    };



    const updateSummaryDataSets = callSummaryDataSets.map((item) => {
        const matchingDataSet = dataPageSummaryData.find(data => data.DtaSetNm === item.DtaSetNm);

        if (matchingDataSet) {
            return getSelectedDataSets(item.DtaSetNm);  // 같은 DtaSetNm이 있으면 데이터를 가져옴
        }
        return null;  // 같은 DtaSetNm이 없으면 null 반환
    }).filter(Boolean);  // null 값을 제거하기 위해 filter(Boolean) 사용

    console.log("updateSummaryDataSets@@22", updateSummaryDataSets);


    //업데이트 시 데이터 세트 값 확인 후 변경
    const listDetaileSummaryDataSets = getSelectedDataSets(callImgData.dmpSummary?.DtaSetNm);

    console.log("updateSummaryDataSets@@22", listDetaileSummaryDataSets);



    const realDataSave = async () => {

        //신규 로직
        const newDataSave = async () => {

            const formData = new FormData();

            files.forEach((file) => {
                formData.append('files', file);
            });

            const resultSaveFileList = sendImgFile.map((item) => ({
                DtaFilePath: item.SaveFile,
                DtaFileNm: item.OriginalFileName,
                DtaSaveFileNm: item.FilePath,
                DtaFileSize: item.Size,
            }));

            const resultSaveDataSets = updateSummaryDataSets[0].map((item) => ({
                DtaSetNo: item.DtaSetNo,
                DmpNo: item.DmpNo,
                DtaFld: item.field,
                DtaTyp: item.type,
                DtaUnit: item.unit,
                DtaMtdIdx: item.index,
                DtaMtdNm: item.name,
                DtaDsc: item.description,
                UserNo : registerdataSets.dmpMain.ProjNo,
                UserNm : registerdataSets.dmpMain.ProjNm,
                DelFlg : "N"
            }));


            try {
                const response = await api.post('/api/data/savedata', {
                    Summary:
                        {
                            DmpNo : registerdataSets.dmpMain.DmpNo,
                            DtaSetNm: dataPageSummaryData[0].DtaSetNm,
                            DtaSetNmSub : dataPageSummaryData[0].DtaSetNmSub,
                            DtaNm: dataPageSummaryData[0].DtaNm,
                            DtaDs : dataPageSummaryData[0].DtaDs,
                            DmpFlg : dataPageSummaryData[0].DmpFlg,
                        },
                    DataMain: resultSaveDataSets,

                    DataSubmit:
                        {
                            DataEmpEmail:"dlrudgh1303@gmail.com",
                            SubmitStatus : 1,
                            OpenStatus: 0,
                            OpenStartDt: "2024-09-02 03:27:41",
                            OpenEndDt: "2024-09-02 03:27:41",
                            InsDt:"2024-09-02 03:27:41",
                            UpdDt:"2024-09-02 03:27:41",
                            UserNo : registerdataSets.dmpMain.UserNo,
                            UserNm : registerdataSets.dmpMain.UserNm,
                            DelFlg: "N"
                        },

                    DataSubmitFile: resultSaveFileList,
                    TmpYn: "N",
                }, { withCredentials: true}).then((res) => res.data);
                alert('신규데이터 저장 성공하셨습니다.');
            } catch (error) {
                console.error('Upload error:', error);
                alert('저장 api를 확인해보세요.');
            }
        }

        //업데이트 로직
        const realDataUpdate = async () => {
            const formData = new FormData();

            files.forEach((file) => {
                formData.append('files', file);
            });

            // 파일 리스트를 생성하는 코드
            const callSaveFileList = showCallImgData.map((item) => {
                if(item.Fstatus === "save") {
                    return {
                        DtaSbmSeqNo: item.DtaSbmSeqNo,
                        DtaFilePath: item.SaveFile,
                        DtaFileNm: item.OriginalFileName,
                        DtaSaveFileNm: item.FilePath,
                        DtaFileSize: item.Size,
                        Fstatus: item.Fstatus,
                    };
                } else if(item.Fstatus === "delete") {
                    return {
                        FileSeqNo: item.FileSeqNo,
                        DtaFilePath: item.DtaFilePath,
                        DtaFileNm: item.DtaFileNm,
                        DtaSaveFileNm: item.DtaSaveFileNm,
                        DtaFileSize: Number(item.DtaFileSize),
                        Fstatus: item.Fstatus,
                    };
                }
                else {
                    return {
                        FileSeqNo: item.FileSeqNo,
                        DtaFilePath: item.DtaFilePath,
                        DtaFileNm: item.DtaFileNm,
                        DtaSaveFileNm: item.DtaSaveFileNm,
                        DtaFileSize: Number(item.DtaFileSize),
                        Fstatus: 'none',
                    };
                }
            });



            const resultUpdateDataSets = listDetaileSummaryDataSets.map((item) => ({
                DtaSetNo: item.DtaSetNo,
                DmpNo: callImgData.dmpSummary.DmpNo,
                DtaFld: item.field,
                DtaTyp: item.type,
                DtaUnit: item.unit,
                DtaMtdIdx: item.index,
                DtaMtdNm: item.name,
                DtaDsc: item.description,
                DelFlg : "N"
            }));

            // const selectedDataSets = getSelectedDataSets(dataPageSummaryData[0].DtaSetNm);

            // const updateSetsData = selectedDataSets.map((item) => {
            //     if (visibility === 'public') {
            //         return {
            //             DtaSetNo: item.DtaSetNo,
            //             DmpNo: item.DmpNo,
            //             DtaFld: item.field,
            //             DtaTyp: item.type,
            //             DtaUnit: item.unit,
            //             DtaMtdIdx: item.index,
            //             DtaMtdNm: item.name,
            //             DtaDsc: item.methoddescription || item.description,
            //             UserNo: "아스트",
            //             UserNm: "작스트",
            //             OpenStatus: 1,
            //             DataEmpEmail: email,      // 이메일 필드
            //             OpenStartDt: startDate,   // 공개 시작일
            //             OpenEndDt: endDate        // 공개 종료일
            //         };
            //     } else {
            //         // 비공개인 경우, 다른 필드들만 설정하거나 빈 객체를 반환할 수 있음
            //         return {
            //             DtaSetNo: item.DtaSetNo,
            //             DmpNo: item.DmpNo,
            //             DtaFld: item.field,
            //             DtaTyp: item.type,
            //             DtaUnit: item.unit,
            //             DtaMtdIdx: item.index,
            //             DtaMtdNm: item.name,
            //             DtaDsc: item.methoddescription || item.description,
            //             UserNo: "아스트",
            //             UserNm: "작스트",
            //             OpenStatus: 0
            //         };
            //     }
            // });

                try {
                    const response = await api.post('/api/data/modifydata', {
                        Summary:
                            {
                                DtaSetNo : callImgData.dmpSummary?.DtaSetNo,
                                DtaSetNm: callImgData.dmpSummary?.DtaSetNm,
                                DtaSetNmSub : callImgData.dmpSummary?.DtaSetNmSub,
                                DtaNm: callImgData.dmpSummary?.DtaNm,
                                DtaDs : callImgData.dmpSummary?.DtaDs,
                                DmpFlg : callImgData.dmpSummary?.DmpFlg
                            },
                        DataMain:
                        resultUpdateDataSets,

                        DataSubmit:
                            {
                                DtaSbmSeqNo : callImgData.dmpSummary.DtaSbmSeqNo
                            },

                        DataSubmitFile:
                        callSaveFileList,
                        TmpYn: "N",

                },{ withCredentials: true}).then((res) => res.data);
                alert('수정 성공했습니다.');
            } catch (error) {
                console.error('Upload error:', error);
                alert('수정 api를 확인해보세요.');
            }
        };


        if(mode === 'update') {
            await realDataUpdate();
        }
        else if(mode ==='create') {
            await newDataSave();
        }

    };

    return(
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpRealData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span>데이터</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터> 데이터 등록</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>데이터 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title">
                            <span className="RealData-info">DMP 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <div className="RealData-task-wrapper">
                                <button className="Real-check-obj" onClick={toggleObjCheck}>등록 DMP 불러오기</button>
                                {showRegisterDmpCheck && <RegisterDmp/>}

                                {
                                    registerdataSets && registerdataSets?.dmpMain || mode === 'create' ?(
                                            <div key={registerdataSets.dmpMain?.DmpNo}className="createCallDMP">
                                                <input type="text" className="edit--box" name="projectNumber" readOnly value={registerdataSets?.dmpMain?.ProjNo}  placeholder="과제번호"/>
                                                <input type="text" className="edit--box" name="projectName"readOnly value={registerdataSets?.dmpMain?.ProjNm} placeholder="과제명"/>
                                                <input type="text" className="edit--box" name="projectLeader" readOnly value={registerdataSets?.dmpMain?.RspnEmpNm} placeholder="연구책임자"/>
                                            </div>
                                    ):(
                                        mode === 'update' && (
                                            <>
                                                <input type="text" className="edit--box" readOnly placeholder="과제번호" value={callImgData.dmpSummary?.ProjNo}/>
                                                <input type="text" className="edit--box" readOnly placeholder="과제명" value={callImgData.dmpSummary?.ProjNm}/>
                                                <input type="text" className="edit--box" readOnly placeholder="연구책임자" value={callImgData.dmpSummary?.RspnEmpNm}/>
                                            </>
                                        ))
                                }
                                <button className="Real-check-obj" >DMP 보기</button>
                            </div>
                            <div>
                                <span className="tast-des">* 등록된 DMP를 선택하면 DMP 정보가 자동으로 입력됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>등록 대상 데이터</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <div className="dmpDataSets-container">
                                { showImportData && <ImportDataSet DmpNo={registerdataSets?.dmpMain?.DmpNo} /> }
                                {
                                    mode === 'create' ? (
                                        dataPageSummaryData?.length > 0 ? (
                                            dataPageSummaryData.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="dataSets-Inputboxs">
                                                        <button className="dataSet-bring" onClick={toggleImportDataSets}>DMP 데이터세트 불러오기</button>
                                                        <div className="selectbox-container">
                                                            <div className="DataPage-selectbox-check">
                                                                <select
                                                                    className="createDataPage"
                                                                    id={`selectBox-${item?.DtaSetNo}`}
                                                                    value={item?.DtaSetNm || ''}
                                                                    onChange={(event) => handleUpdateDataPageChange(item?.DtaSetNo, event)}
                                                                >
                                                                    <option value=''>-- 구분 --</option>
                                                                    <option value="시료데이터">시료데이터</option>
                                                                    <option value="방법데이터">방법데이터</option>
                                                                    <option value="결과데이터">결과데이터</option>
                                                                </select>
                                                                <select
                                                                    className="createDataPage"
                                                                    id="selectBox"
                                                                    value={item?.DtaSetNmSub || ''}
                                                                    onChange={(e) => handleUpdateRequierChange(item?.DtaSetNo, e)}
                                                                >
                                                                    <option value=''>-- 선택 * 필수 --</option>
                                                                    <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                                    <option value="Food, Processed">Food, Processed</option>
                                                                    <option value="Food, Derived">Food, Derived</option>
                                                                    <option value="Animal">Animal</option>
                                                                    <option value="Human">Human</option>
                                                                    <option value="Invertebrate">Invertebrate</option>
                                                                    <option value="Microbe">Microbe</option>
                                                                    <option value="Cell, Animal">Cell, Animal</option>
                                                                    <option value="Cell, Human">Cell, Human</option>
                                                                    <option value="Cell, Plant">Cell, Plant</option>
                                                                    <option value="etc">etc</option>
                                                                </select>

                                                                <input
                                                                    type="text"
                                                                    className="edit--box"
                                                                    value={item?.DtaNm || ''}
                                                                    onChange={(e) => handleDataPageInputChange(item?.DtaSetNo, e)}
                                                                    id="data-type-name"
                                                                    name="DtaNm"
                                                                    placeholder="데이터 타입명"
                                                                />
                                                                <input type="text"
                                                                       className="edit--box"
                                                                       value={item?.DtaDs || ''}
                                                                       onChange={(e) => handleDataPageDES(item?.DtaSetNo, e)}
                                                                       id="data-type-desc"
                                                                       name="DtaDs"
                                                                       placeholder="데이터 타입 설명"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="datePage-targetData-des">
                                                        <span className="tast-des">* 등록된 DMP 데이터세트를 선택하면 데이터세트가 자동으로 입력됩니다.</span>
                                                        <span className="tast-des">* TYPE은 문자열:"STRING", 숫자:"NUMERIC", 날짜:"DATE", 파일명:"FILE" 로 사용을 권장합니다.</span>
                                                        <ToastGrid DateSets={callToastDataSets} MethodData={item?.DtaSetNm || ''} />
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            // 데이터가 없을 때 기본 UI 렌더링
                                            <React.Fragment>
                                                <div className="dataSets-Inputboxs">
                                                    <button className="dataSet-bring" onClick={toggleImportDataSets}>DMP 데이터세트 불러오기</button>
                                                    <div className="selectbox-container">
                                                        <div className="DataPage-selectbox-check">
                                                            <select className="createDataPage" id="selectBox" onChange={(event) => handleUpdateDataPageChange(null, event)}>
                                                                <option value=''>-- 구분 --</option>
                                                                <option value="시료데이터">시료데이터</option>
                                                                <option value="방법데이터">방법데이터</option>
                                                                <option value="결과데이터">결과데이터</option>
                                                            </select>
                                                            <select className="createDataPage" id="selectBox" onChange={(e) => handleUpdateRequierChange(null, e)}>
                                                                <option value=''>-- 선택 * 필수 --</option>
                                                                <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                                <option value="Food, Processed">Food, Processed</option>
                                                                <option value="Food, Derived">Food, Derived</option>
                                                                <option value="Animal">Animal</option>
                                                                <option value="Human">Human</option>
                                                                <option value="Invertebrate">Invertebrate</option>
                                                                <option value="Microbe">Microbe</option>
                                                                <option value="Cell, Animal">Cell, Animal</option>
                                                                <option value="Cell, Human">Cell, Human</option>
                                                                <option value="Cell, Plant">Cell, Plant</option>
                                                                <option value="etc">etc</option>
                                                            </select>

                                                            <input
                                                                type="text"
                                                                className="edit--box"
                                                                onChange={(e) => handleDataPageInputChange(null, e)}
                                                                id="data-type-name"
                                                                name="DtaNm"
                                                                placeholder="데이터 타입명"
                                                            />
                                                            <input type="text"
                                                                   className="edit--box"
                                                                   onChange={(e) => handleDataPageDES(null, e)}
                                                                   id="data-type-desc"
                                                                   name="DtaDs"
                                                                   placeholder="데이터 타입 설명"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="datePage-targetData-des">
                                                    <span className="tast-des">* 등록된 DMP 데이터세트를 선택하면 데이터세트가 자동으로 입력됩니다.</span>
                                                    <span className="tast-des">* TYPE은 문자열:"STRING", 숫자:"NUMERIC", 날짜:"DATE", 파일명:"FILE" 로 사용을 권장합니다.</span>
                                                    <ToastGrid DateSets={[]} MethodData="" />
                                                </div>
                                            </React.Fragment>
                                        )
                                    ) : (
                                        mode === 'update' && (
                                            <>
                                                <div className="dataSets-Inputboxs">
                                                    <button className="dataSet-bring" onClick={toggleImportDataSets}>DMP 데이터세트 불러오기</button>
                                                    <div className="selectbox-container">
                                                        <div className="DataPage-selectbox-check">
                                                            <select
                                                                className="createDataPage"
                                                                value={callImgData.dmpSummary?.DtaSetNm || ''}
                                                                readonly
                                                            >
                                                                <option value=''>-- 구분 --</option>
                                                                <option value="시료데이터">시료데이터</option>
                                                                <option value="방법데이터">방법데이터</option>
                                                                <option value="결과데이터">결과데이터</option>
                                                            </select>
                                                            <select
                                                                className="createDataPage"
                                                                id="selectBox"
                                                                value={callImgData.dmpSummary?.DtaSetNmSub || ''}
                                                                readonly
                                                            >
                                                                <option value=''>-- 선택 * 필수 --</option>
                                                                <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                                <option value="Food, Processed">Food, Processed</option>
                                                                <option value="Food, Derived">Food, Derived</option>
                                                                <option value="Animal">Animal</option>
                                                                <option value="Human">Human</option>
                                                                <option value="Invertebrate">Invertebrate</option>
                                                                <option value="Microbe">Microbe</option>
                                                                <option value="Cell, Animal">Cell, Animal</option>
                                                                <option value="Cell, Human">Cell, Human</option>
                                                                <option value="Cell, Plant">Cell, Plant</option>
                                                                <option value="etc">etc</option>
                                                            </select>

                                                            <input
                                                                type="text"
                                                                className="edit--box"
                                                                id="data-type-name"
                                                                name="DtaNm"
                                                                placeholder="데이터 타입명"
                                                                value={callImgData.dmpSummary?.DtaNm || ''}
                                                                readonly
                                                            />
                                                            <input type="text"
                                                                   className="edit--box"
                                                                   id="data-type-desc"
                                                                   name="DtaDs"
                                                                   placeholder="데이터 타입 설명"
                                                                   value={callImgData.dmpSummary?.DtaDs || ''}
                                                                   readonly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="datePage-targetData-des">
                                                    <span className="tast-des">* 등록된 DMP 데이터세트를 선택하면 데이터세트가 자동으로 입력됩니다.</span>
                                                    <span className="tast-des">* TYPE은 문자열:"STRING", 숫자:"NUMERIC", 날짜:"DATE", 파일명:"FILE" 로 사용을 권장합니다.</span>
                                                    <ToastGrid MethodData={callImgData.dmpSummary?.DtaSetNm} DateSets={callImgData?.data}/>
                                                </div>
                                            </>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        <div className="RealData-send">
                            <span>데이터 제출</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-sendFile-container">
                            <div className="research-method-title">
                                <label htmlFor="fileDiagram">
                                    <img id="imgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                </label>
                            </div>
                            <input type="file"  id="fileDiagram" name="dataSubmitFiles" multiple  onChange={handleFileChange} className="search-input" placeholder="파일 이름" />
                            {Array.isArray(files) && files.length > 0 ? (
                                files.map((item, index) => (
                                    <div className="fileName" key={index}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                            alt="close"
                                            onClick={() => closeFile(index)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <div>
                                            <p>{item.name}</p> {/* 파일의 이름을 표시합니다 */}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                Array.isArray(showCallImgData) && showCallImgData.length > 0 &&
                                showCallImgData.map((item, index) => {
                                    if (item.Fstatus === "delete") {
                                        // 삭제된 항목은 렌더링하지 않음
                                        return null;
                                    } else if (item.Fstatus === "save") {
                                        // 저장된 항목은 저장된 상태로 렌더링
                                        return (
                                            <div className="fileName" key={index}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.OriginalFileName}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        // "Fstatus" 값이 없거나 다른 경우 기본 렌더링
                                        return (
                                            <div className="fileName" key={index}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.name || item.DtaFileNm}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            )}

                            <div className="RealData-btnContainer">
                                <button className="RealData-save-button" onClick={handleUpload}>
                                    전송
                                </button>
                            </div>

                        </div>
                    </div>

                    {
                        mode === 'update' && (
                            <>
                                <div className="public-dataContainer">
                                    <div>공개데이터 </div>
                                    <div>
                                        <div className="label-btn">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="public"
                                                    checked={visibility === 'public'}
                                                    onChange={(e) => setVisibility(e.target.value)}
                                                />
                                                공개
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="private"
                                                    checked={visibility === 'private'}
                                                    onChange={(e) => setVisibility(e.target.value)}
                                                />
                                                비공개
                                            </label>
                                        </div>

                                        {visibility === 'public' && (
                                            <div>
                                                <div className="label-name">
                                                    <label>
                                                        start-Date:
                                                        <input
                                                            type="datetime-local"
                                                            value={startDate}
                                                            onChange={(e) => setStartDate(e.target.value)}
                                                        />
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        end-Date:
                                                        <input
                                                            type="datetime-local"
                                                            value={endDate}
                                                            onChange={(e) => setEndDate(e.target.value)}
                                                        />
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        담당자  이메일:
                                                        <input
                                                            type="email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            placeholder="your.email@example.com"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    }
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list">
                        <Link to="/dmpRealData/dmpRealDataList/dmpRealDataList"> 취소</Link>
                    </button>
                    <button id="save-btn"  onClick={realDataSave} >
                        {
                            mode === 'create' ? '저장' : '수정'
                        }
                        </button>
                </div>
            </div>
        </>
    )
}

export default DmpRealDataCreate
