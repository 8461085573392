import {create} from "zustand";

// 데이터 관리 계획에 첫 dmp 작성 시 연구데이터 공개 계획
const useNewPublicSetStore = create(set => ({
    newPublicdataSets: [],
    setNewPublicdataSets: (newDmpDataSets) => set({ newPublicdataSets: newDmpDataSets }),
}));


const useNewDmpProductionPlan = create(set => ({
    newProductionPlandataSets: [],
    addNewProductionPlanDataSet: (newDataSet) => set(state => ({
        newProductionPlandataSets: [...state.newProductionPlandataSets, newDataSet]
    })),
    updateProductionPlanDataSet: (id, updatedDataSet) => set(state => ({
        newProductionPlandataSets: state.newProductionPlandataSets.map(dataSet =>
            dataSet.id === id ? { ...dataSet, ...updatedDataSet } : dataSet
        )
    })),
}));

//new 연구데이터 관리 계획
const useNewDmpManagementPlan  = create(set => ({
    newManagementPlandataSets: [],
    addnewManagementPlandataSet: (newDataSet) => set(state => ({
        newManagementPlandataSets: [...state.newManagementPlandataSets, newDataSet]
    })),
    updateManagementPlandataSet: (id, updatedDataSet) => set(state => ({
        newManagementPlandataSets: state.newManagementPlandataSets.map(dataSet =>
            dataSet.id === id ? { ...dataSet, ...updatedDataSet } : dataSet
        )
    })),
}));

const useNewDmpPublicPlan  = create(set => ({
    newPublicPlandataSets: [],
    addnewPublicPlandataSet: (newDataSet) => set(state => ({
        newPublicPlandataSets: [...state.newPublicPlandataSets, newDataSet]
    })),
    updatePublicPlandataSet: (id, updatedDataSet) => set(state => ({
        newPublicPlandataSets: state.newPublicPlandataSets.map(dataSet =>
            dataSet.id === id ? { ...dataSet, ...updatedDataSet } : dataSet
        )
    })),
}));

//시료데이터
const useNewDmpToastData  = create(set => ({
    newDmpToastDataSets: [],
    addnewDmpToastDataSets: (newDataSet) => set(state => ({
        newDmpToastDataSets: [...state.newDmpToastDataSets, newDataSet]
    })),
    updateNewDmpToastDataSets: (id, updatedDataSet) => set(state => ({
        newDmpToastDataSets: state.newDmpToastDataSets.map(dataSet =>
            dataSet.id === id ? { ...dataSet, ...updatedDataSet } : dataSet
        )
    })),
}));

//방법데이터

const useDmpStore = create(set => ({
    sampleData: [],
    methodData: [],
    resultData: [],
    setSampleData: (newData) => set(state => ({ sampleData: newData })),
    setMethodData: (newData) => set(state => ({ methodData: newData })),
    setResultData: (newData) => set(state => ({ resultData: newData })),
}));


export {useNewPublicSetStore, useDmpStore, useNewDmpProductionPlan, useNewDmpManagementPlan, useNewDmpPublicPlan, useNewDmpToastData}