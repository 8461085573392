import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import './dmpRealDataList.css';
import api from '../../api';
import {useCallImgStore} from "../../stores/ImgDataSets";

function DmpRealDataList() {

    const [dataList, setDataList] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const navigate = useNavigate();


    //이미지 불러오기
    const {setTaskDataSets} = useCallImgStore();

    useEffect(() => {
        api.get(`/api/data/datalist?kw&ps&pl`, {
            withCredentials: true
        }).then(res =>{
            setDataCount(res.data.pages.Total)
            setDataList(res.data.item)
        });
    }, []);

    const handleRowClick = (DtaSbmSeqNo) => {
        // 동적 경로로 이동 (DtaSbmSeqNo가 포함된 URL)
        navigate(`/dmpRealData/dmpRealDataModify/${DtaSbmSeqNo}`);
    };

    return(
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span>데이터</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 > 데이터 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                </div>
                <div>
                    <div className="btn-Realcontainer">
                        <div>총 {dataCount}건</div>
                        <div className="btn-Realcheck">
                            <button className="Realcreate02-dmp">
                                <Link to="/dmpRealData/dmpRealDataCreate/dmpRealDataCreate">
                                    데이터 등록
                                </Link>
                            </button>
                            <button className="Realsend02-dmp">
                                <Link to="/dmpRealData/dmpRealDataSend/dmpRealDataSend">
                                    데이터 제출
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmpReallist-title">
                            <th>과제번호</th>
                            <th>과제명</th>
                            <th>데이터셋/설명</th>
                            <th>연구 책임자</th>
                            <th>데이터 상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataList?.map((row, index) => (
                            <tr key={index} className="dmpReallist-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.ProjNm}</td>
                                <td
                                    className="update-page"
                                    onClick={() => handleRowClick(row.DtaSbmSeqNo)}
                                >
                                    {row.DtaSetNm} / {row.DtaDs}
                                </td>
                                <td>{row.RspnEmpNm}</td>
                                <td>{row.DtaSbmSeqNo}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default DmpRealDataList;
