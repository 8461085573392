import React, { useEffect, useState } from "react";
import './taskCheck.css';
import api from '../api';
import { useTaskDataStore} from "../stores/lastDmpData";

function LastDmpShow() {
    const [,setObjnumber] = useState("");
    const [,setLeaderName] = useState("");
    const [,setObjDate] = useState("");
    const [tableData, setTableData] = useState([]);

    // 팝업 창 닫기
    const [isPopupOpen, setIsPopOpen] = useState(true);

    // zustand를 통한 전역 데이터 관리 코드
    const { setTaskDataSets} = useTaskDataStore();

    const closePopup = () => {
        setIsPopOpen(false);
    }

    useEffect(() => {
        api.get(`/api/dmp/proj?UserNo=admin`, {withCredentials: true})
            .then((res) => {
                const newTableData = res.data.map((data) => ({
                    id: data.ProjNo,
                    lead: data.RspnEmpNm,
                    lastModified: data.ProjAnnl,
                    dateSt: data.ProjDateSt
                }));


                if (newTableData.length > 0) {
                    const firstData = newTableData[0];
                    setObjnumber(firstData.id);
                    setLeaderName(firstData.lead);
                    setObjDate(firstData.lastModified);
                }

                console.log(res.data);

                setTableData(newTableData);
            })
            .catch(error => {
                console.log("lastDMP의 api를 확인해보세요.", error);
            });
    }, [setTableData]);

    const objCheckHandler = (id) => {
        api.get(`/api/dmp/proj?UserNo=admin`, {withCredentials: true})
            .then((res) => {
                // 클릭한 row.id와 일치하는 데이터를 필터링
                const updatedData = res.data.filter(dataItem => {
                    return id === dataItem.ProjNo;
                });
                setTaskDataSets(updatedData);
            });
        setIsPopOpen(false);
    };

    return (
        <>
            {isPopupOpen && (
                <div className="taskCheck-container">
                    <div className="taskCheck-wrapper">
                        <div className="taskCheck-title01">
                            <div className="taskCheck-context01">
                                <h1>과제 조회</h1>
                                <img
                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                    alt="close"
                                    onClick={closePopup}
                                    style={{ cursor: 'pointer'}} // 클릭 가능하게 커서를 변경합니다.
                                />
                            </div>
                            <div className="taskCheck-context02">
                                <span>※과제가 보이지 않는 경우 DMP 목록에서 작성된 내용이 있는지 확인하세요.</span>
                                <span>작성된 내용이 있는 경우 수정하여 작성해주세요. DMP는 과제별 연차당 1회 작성합니다.</span>
                            </div>
                        </div>
                        <div className="taskCheck-inputbox">
                            <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                            <input type="text" placeholder="과제 데이터를 조회하세요."   />
                        </div>

                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="taskCheck-title">
                                <th>과제번호</th>
                                <th>연구 책임자</th>
                                <th>과제연차</th>
                                <th>연차시작일</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData.map((row) => (
                                <tr key={row.id} className="taskCheck-Data">
                                    <td>
                                        <button onClick={() => objCheckHandler(row.id)}>
                                            {row.id}
                                        </button>
                                    </td>
                                    <td>{row.lead}</td>
                                    <td>{row.lastModified}</td>
                                    <td>{row.dateSt}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export default LastDmpShow;
